import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import styles from './Finances.module.scss';

import { routes } from 'enums';
import { LeftArrow, RightArrow, Search, Pencil, SaveIcon } from 'assets/icons';
import { useActions, useAppSelector, useDebounce } from 'hooks';
import { doctorThunk, notificationThunk, notificationsSelect, userSelect } from 'state';
import { CustomButton } from 'components/CustomButton';
import { NotificationModal } from 'components/NotificationModal';
import clsx from 'clsx';
import { userThunk } from 'state/thunks/userThunk/userThunk';
import { usersSelect } from 'state/selectors/userSelectors';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { doctorsSelect } from 'state/selectors/doctorSelectors';
import { stat } from 'fs/promises';
import { CustomDropdown } from 'ui-kit/CustomDropdown';
import { financeThunk } from 'state/thunks/financeThunk/financeThunk';
import { financesSelect } from 'state/selectors/financeSelectors';
import { EmptyStub } from 'components/EmptyStub';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const FinanceItem = ({
  user,
  columns,
  columnWidth,
  handleEditClick,
  isEditable,
  handleChangePaymentStatus,
}: any) => {
  console.log(user);
  const itemsObj: any = {
    'Provider name': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.name}
      </div>
    ),
    'Specialist Type': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.category}
      </div>
    ),
    Speciality: (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.speciality ? user.user.doctor.speciality : '-'}
      </div>
    ),
    'For month': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {months[user.month]}
      </div>
    ),
    Amount: (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        ${user.amount}
      </div>
    ),
    'Due date': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {'-'}
      </div>
    ),
    'Payment status': !isEditable ? (
      <div
        className={styles.customCol1}
        style={{ width: columnWidth + '%', fontWeight: 'bold' }}
      >
        {user.paymentStatus}
      </div>
    ) : (
      <div
        className={styles.customCol1}
        style={{ width: columnWidth + '%', fontWeight: 'bold' }}
      >
        <FormControl sx={{ m: 1, minWidth: 70 }}>
          <Select
            value={user.paymentStatus}
            onChange={e => {
              handleEditClick();
              handleChangePaymentStatus(user.id, {
                ...user,
                paymentStatus: e.target.value,
              });
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            style={{ height: 40, padding: 0 }}
          >
            <MenuItem value={'Not Paid'}>Not Paid</MenuItem>
            <MenuItem value={'Paid'}>Paid</MenuItem>
          </Select>
        </FormControl>
      </div>
    ),
    Status: (
      <div
        className={styles.customCol1}
        id="Status"
        style={
          user.user.doctor.status
            ? { color: 'green', fontWeight: 'bold' }
            : { color: 'red', fontWeight: 'bold' }
        }
      >
        {user.user.doctor.status ? 'Active' : 'Blocked'}
      </div>
    ),
  };

  return (
    <div className={styles.tableItem}>
      {columns.map((column: any) => {
        if (column.isSelect) {
          return itemsObj[column.name];
        }
      })}
      <div
        onClick={handleEditClick}
        className={styles.customCol1}
        style={{ width: columnWidth / 2 + '%', cursor: 'pointer' }}
      >
        {isEditable ? <SaveIcon /> : <Pencil />}
      </div>
    </div>
  );
};

const defaultColumns = [
  {
    name: 'Provider name',
    isSelect: true,
  },
  {
    name: 'Specialist Type',
    isSelect: true,
  },
  {
    name: 'Speciality',
    isSelect: true,
  },
  {
    name: 'For month',
    isSelect: true,
  },
  {
    name: 'Amount',
    isSelect: true,
  },
  {
    name: 'Due date',
    isSelect: true,
  },
  {
    name: 'Payment status',
    isSelect: true,
  },
  {
    name: 'Status',
    isSelect: true,
  },
];

export const Finances = () => {
  const navigate = useNavigate();
  const [isNotificationModal, setNotificationModal] = useState(false);
  const { getUsers } = useActions(userThunk);
  const { getDoctors } = useActions(doctorThunk);
  const { getFinances, editFinance } = useActions(financeThunk);
  const users: any = useAppSelector(usersSelect);
  const doctors: any = useAppSelector(doctorsSelect);
  const finances: any = useAppSelector(financesSelect);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [bodyMessage, setBodyMessage] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);
  const [columnWidth, setColumnWidth] = useState(100);
  const [selectedEditableItem, setSelectedEditableItem]: any = useState([]);

  const handleColumnChange = (name: any) => {
    console.log(name);
    setColumns(
      columns.map(el => {
        if (el.name === name) {
          return {
            ...el,
            isSelect: !el.isSelect,
          };
        } else {
          return el;
        }
      }),
    );
  };

  // useEffect(() => {
  //   getColumnWidth();
  // }, [columns]);

  useEffect(() => {
    const columnCount = getColumnWidth();
    setColumnWidth(columnCount);
  }, [columns]);

  const getColumnWidth = () => {
    const columnCount = columns.reduce((accumulator: any, el: any) => {
      if (el.isSelect) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    console.log(columnCount);

    return 100 / columnCount;
  };

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const convertStatusValue = () => {
    switch (statusValue) {
      case 'active':
        return true;
        break;
      case 'blocked':
        return false;
        break;
      case '':
        return '';
    }
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      setIsSearching(true);
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        category: selectedCategory,
        status: convertStatusValue(),
      });
    } else {
      getFinances({
        q: searchValue,
        take: itemPerPage,
        page: '',
        category: selectedCategory,
        status: convertStatusValue(),
      });
    }
  }, [debouncedSearchValue]);

  const itemPerPage = 2;

  useEffect(() => {
    getUsers({ q: '', take: itemPerPage, page: '' });
    getFinances({
      q: '',
      take: itemPerPage,
      page: '',
      category: selectedCategory,
      status: convertStatusValue(),
    });
  }, []);

  useEffect(() => {
    console.log('----');
    console.log(finances);
  }, [finances]);

  useEffect(() => {
    getFinances({
      q: debouncedSearchValue,
      take: itemPerPage,
      page: '',
      category: selectedCategory,
      status: convertStatusValue(),
    });
  }, [selectedCategory]);

  const handleLeftArrowClick = () => {
    if (finances?.page > 1) {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: finances?.page - 1,
        category: selectedCategory,
        status: convertStatusValue(),
      });
    }
  };

  const handleRightArrowClick = () => {
    if (finances?.page * itemPerPage < finances.count) {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: finances?.page + 1,
        category: selectedCategory,
        status: convertStatusValue(),
      });
    }
  };

  useEffect(() => {
    if (statusValue === '') {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        status: '',
        category: selectedCategory,
      });
    }

    if (statusValue === 'active') {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        status: convertStatusValue(),
        category: selectedCategory,
      });
    }
    if (statusValue === 'blocked') {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        status: convertStatusValue(),
        category: selectedCategory,
      });
    }
  }, [statusValue]);

  const handleEditClick = (index: any) => {
    if (selectedEditableItem.includes(index)) {
      setSelectedEditableItem(selectedEditableItem.filter((el: any) => el !== index));
    } else {
      setSelectedEditableItem([...selectedEditableItem, index]);
    }
  };

  const handleChangePaymentStatus = (id: any, data: any) => {
    editFinance({ data, id });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.flex}>
          <div className={styles.title}>Finances</div>
          <div className={styles.pagination}>
            {finances?.page * itemPerPage + 1 - itemPerPage}-
            {finances?.page * itemPerPage} from {finances?.count}
          </div>
          <div className={styles.arrowContainer} onClick={handleLeftArrowClick}>
            <LeftArrow />
          </div>
          <div className={styles.arrowContainer} onClick={handleRightArrowClick}>
            <RightArrow />
          </div>
        </div>

        <div className={styles.flexFilters}>
          <div>
            <FormControl sx={{ m: 1, minWidth: 255 }}>
              <Select
                value={selectedCategory}
                onChange={e => {
                  setSelectedCategory(e.target.value);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ height: 40, padding: 0 }}
              >
                <MenuItem value={''}>Type: All</MenuItem>
                <MenuItem value={'GP/Emergency doctor'}>
                  Type: GP/Emergency doctor
                </MenuItem>
                <MenuItem value={'Nurse'}>Type: Nurse</MenuItem>
                <MenuItem value={'Specialist doctor'}>Type: Specialist doctor</MenuItem>
                <MenuItem value={'Consultant doctor'}>Type: Consultant doctor</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={statusValue}
                onChange={e => {
                  setStatusValue(e.target.value);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ height: 40, padding: 0 }}
              >
                <MenuItem value={''}>Status: All</MenuItem>
                <MenuItem value={'active'}>Status: Active</MenuItem>
                <MenuItem value={'blocked'}>Status: Blocked</MenuItem>
              </Select>
            </FormControl>
          </div>

          <CustomDropdown columns={columns} handleColumnChange={handleColumnChange} />

          <div className={styles.inputContainerSearch}>
            <div className={styles.searchIconContainer}>
              <Search />
            </div>

            <input
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
              placeholder="Search by ProviderName"
              className={styles.inputSearch}
            />
          </div>
        </div>
      </div>

      {isNotificationModal && (
        <NotificationModal setNotificationModal={setNotificationModal} />
      )}

      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {columns.map(el =>
            el.isSelect ? (
              <div
                className={clsx([styles.customCol1, styles.bold])}
                style={{ width: columnWidth + '%' }}
              >
                {el.name}
              </div>
            ) : (
              <></>
            ),
          )}
          <div
            className={clsx([styles.customCol1, styles.bold])}
            style={{ width: columnWidth / 2 + '%' }}
          ></div>
        </div>

        {finances?.rows && finances?.rows[0] ? (
          finances?.rows?.map((user: any, index: any) => {
            return (
              <FinanceItem
                handleChangePaymentStatus={handleChangePaymentStatus}
                handleEditClick={() => handleEditClick(index)}
                isEditable={selectedEditableItem.includes(index)}
                user={user}
                columns={columns}
                columnWidth={columnWidth}
              />
            );
          })
        ) : (
          <EmptyStub />
        )}
      </div>
    </div>
  );
};
