import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePasswordRequest,
  createNotificationRequest,
  editUserRequest,
  getMeRequest,
  getNotificationRequest,
  getPatientVisitsRequest,
  getUserRequest,
  getUsersRequest,
  logInRequest,
  LoginRequestType,
  restorePasswordRequest,
  signUpRequest,
  SignUpRequestType,
  verifyResetRequest,
} from 'api';
import { LsKeys, routes } from 'enums';
import { authActions } from 'state';

export const getUsers = createAsyncThunk(
  'users/get',
  async (query: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getUsersRequest(query);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getUser = createAsyncThunk(
  'user/get',
  async (id: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getUserRequest(id);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const editUser = createAsyncThunk(
  'user/patch',
  async ({ data, id }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await editUserRequest(id, data);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getPatientVisits = createAsyncThunk(
  'visits/get',
  async (query: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getPatientVisitsRequest(query);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const userThunk = {
  getUsers,
  getUser,
  editUser,
  getPatientVisits,
};
