import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g opacity="0.5">
      <path
        d="M4 20.0001H8L18.5 9.50006C19.6046 8.39549 19.6046 6.60463 18.5 5.50006C17.3954 4.39549 15.6046 4.39549 14.5 5.50006L4 16.0001V20.0001"
        stroke="#3C3C60"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 6.5L17.5 10.5"
        stroke="#3C3C60"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
