import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import styles from './Users.module.scss';

import { routes } from 'enums';
import { LeftArrow, RightArrow, Search } from 'assets/icons';
import { useActions, useAppSelector, useDebounce } from 'hooks';
import { notificationThunk, notificationsSelect, userSelect } from 'state';
import { CustomButton } from 'components/CustomButton';
import { NotificationModal } from 'components/NotificationModal';
import clsx from 'clsx';
import { userThunk } from 'state/thunks/userThunk/userThunk';
import { usersSelect } from 'state/selectors/userSelectors';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CustomDropdown } from 'ui-kit/CustomDropdown';
import { EmptyStub } from 'components/EmptyStub';

const NotificationItem = ({ user, columns, columnWidth }: any) => {
  const navigate = useNavigate();

  const itemsObj: any = {
    'Users name': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user['patient.name']}
      </div>
    ),
    Phone: (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user?.phone}
      </div>
    ),
    'Phone of relative': (
      <div style={{ width: columnWidth + '%' }} className={styles.customCol1}>
        {user['patient.phoneRelative']}
      </div>
    ),
    Email: (
      <div style={{ width: columnWidth + '%' }} className={styles.customCol1}>
        {user['patient.email']}
      </div>
    ),
    'Number of visits': (
      <div style={{ width: columnWidth + '%' }} className={styles.customCol1}>
        {user?.visitCount}
      </div>
    ),
    Address: (
      <div style={{ width: columnWidth + '%' }} className={styles.customCol1}>
        {user['patient.address']}
      </div>
    ),
    Status: (
      <div
        className={styles.customCol1}
        style={
          user['patient.status']
            ? { color: 'green', fontWeight: 'bold', width: columnWidth + '%' }
            : { color: 'red', fontWeight: 'bold', width: columnWidth + '%' }
        }
      >
        {user['patient.status'] ? 'Active' : 'Blocked'}
      </div>
    ),
  };

  return (
    <div
      className={styles.tableItem}
      onClick={() => {
        console.log('click');
        navigate(routes.MAIN.USER + '/' + user.id);
      }}
    >
      {columns.map((column: any) => {
        if (column.isSelect) {
          return itemsObj[column.name];
        }
      })}
    </div>
  );
};

const defaultColumns = [
  {
    name: 'Users name',
    isSelect: true,
  },
  {
    name: 'Phone',
    isSelect: true,
  },
  {
    name: 'Phone of relative',
    isSelect: true,
  },
  {
    name: 'Email',
    isSelect: true,
  },
  {
    name: 'Number of visits',
    isSelect: true,
  },
  {
    name: 'Address',
    isSelect: true,
  },
  {
    name: 'Status',
    isSelect: true,
  },
];

export const Users = () => {
  const navigate = useNavigate();
  const [isNotificationModal, setNotificationModal] = useState(false);
  const { getUsers } = useActions(userThunk);
  const users: any = useAppSelector(usersSelect);
  const [selectedCategory, setSelectedCategory] = useState('GP/Emergency doctor');
  const [bodyMessage, setBodyMessage] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [statusValue, setStatusValue] = useState('all');
  const [isSearching, setIsSearching] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);
  const [columnWidth, setColumnWidth] = useState(100);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const handleColumnChange = (name: any) => {
    console.log(name);
    setColumns(
      columns.map(el => {
        if (el.name === name) {
          return {
            ...el,
            isSelect: !el.isSelect,
          };
        } else {
          return el;
        }
      }),
    );
  };

  const getStatusValue = () => {
    if (statusValue === 'active') return 'true';
    if (statusValue === 'blocked') return 'false';
    if (statusValue === 'all') return '';
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      setIsSearching(true);
      getUsers({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        status: getStatusValue(),
      });
    } else {
      getUsers({ q: searchValue, take: itemPerPage, page: '', status: getStatusValue() });
    }
  }, [debouncedSearchValue]);

  const itemPerPage = 20;

  useEffect(() => {
    getUsers({ q: '', take: itemPerPage, page: '', status: getStatusValue() });
  }, []);

  const handleAdd = () => {
    const data = {
      doctorCategories: selectedCategory,
      body: bodyMessage,
    };

    setSelectedCategory('GP/Emergency doctor');
    setBodyMessage('');
    setNotificationModal(false);
  };

  const handleLeftArrowClick = () => {
    console.log(users);
    if (users?.page > 1) {
      getUsers({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: users?.page - 1,
        status: getStatusValue(),
      });
    }
  };

  const handleRightArrowClick = () => {
    if (users?.page * itemPerPage < users.count) {
      getUsers({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: users?.page + 1,
        status: getStatusValue(),
      });
    }
  };

  useEffect(() => {
    if (statusValue === 'all') {
      getUsers({ q: debouncedSearchValue, take: itemPerPage, page: '' });
    }

    if (statusValue === 'active') {
      getUsers({ q: debouncedSearchValue, take: itemPerPage, page: '', status: 'true' });
    }
    if (statusValue === 'blocked') {
      getUsers({ q: debouncedSearchValue, take: itemPerPage, page: '', status: 'false' });
    }
  }, [statusValue]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.flex}>
          <div className={styles.title}>Users</div>
          <div className={styles.pagination}>
            {users?.page * itemPerPage + 1 - itemPerPage}-{users?.page * itemPerPage} from{' '}
            {users?.count}
          </div>
          <div className={styles.arrowContainer} onClick={handleLeftArrowClick}>
            <LeftArrow />
          </div>
          <div className={styles.arrowContainer} onClick={handleRightArrowClick}>
            <RightArrow />
          </div>
        </div>

        <div className={styles.filterflex}>
          <div className={styles.statusContainer}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={statusValue}
                onChange={e => {
                  setStatusValue(e.target.value);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ height: 40, padding: 0 }}
              >
                <MenuItem value={'all'}>Status: All</MenuItem>
                <MenuItem value={'active'}>Status: Active</MenuItem>
                <MenuItem value={'blocked'}>Status: Blocked</MenuItem>
              </Select>
            </FormControl>
          </div>

          <CustomDropdown columns={columns} handleColumnChange={handleColumnChange} />

          <div className={styles.inputContainerSearch}>
            <div className={styles.searchIconContainer}>
              <Search />
            </div>

            <input
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
              placeholder="Search by Username"
              className={styles.inputSearch}
            />
          </div>
        </div>
      </div>

      {isNotificationModal && (
        <NotificationModal setNotificationModal={setNotificationModal} />
      )}

      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {columns.map(el =>
            el.isSelect ? (
              <div
                className={clsx([styles.customCol1, styles.bold])}
                style={{ width: columnWidth + '%' }}
              >
                {el.name}
              </div>
            ) : (
              <></>
            ),
          )}
        </div>

        {users.rows && users.rows[0] ? (
          users?.rows?.map((user: any) => {
            return (
              <NotificationItem user={user} columns={columns} columnWidth={columnWidth} />
            );
          })
        ) : (
          <EmptyStub />
        )}
      </div>
    </div>
  );
};
