import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { clsx } from 'clsx';
import './Button.scss';

type ButtonProps = {
  variant?: 'btn1' | 'btn2';
  fullWidth?: boolean;
} & ComponentPropsWithoutRef<'button'>;

export const Button = forwardRef<ElementRef<'button'>, ButtonProps>(
  ({ variant = 'btn1', className, fullWidth, ...rest }, ref) => {
    return (
      <button
        type="button"
        className={clsx(variant, fullWidth && 'fullWidth', className)}
        {...rest}
        ref={ref}
      />
    );
  },
);
