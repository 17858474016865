import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="28"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M3 14.2499V12.7499H4.5V7.4999C4.5 6.4624 4.8125 5.5404 5.4375 4.7339C6.0625 3.9274 6.875 3.3994 7.875 3.1499V2.6249C7.875 2.3124 7.9845 2.04665 8.2035 1.82765C8.4225 1.60865 8.688 1.4994 9 1.4999C9.3125 1.4999 9.57825 1.6094 9.79725 1.8284C10.0162 2.0474 10.1255 2.3129 10.125 2.6249V3.1499C11.125 3.3999 11.9375 3.92815 12.5625 4.73465C13.1875 5.54115 13.5 6.4629 13.5 7.4999V12.7499H15V14.2499H3ZM9 16.4999C8.5875 16.4999 8.23425 16.3529 7.94025 16.0589C7.64625 15.7649 7.4995 15.4119 7.5 14.9999H10.5C10.5 15.4124 10.353 15.7657 10.059 16.0597C9.765 16.3537 9.412 16.5004 9 16.4999ZM1.5 7.4999C1.5 6.2499 1.77825 5.1029 2.33475 4.0589C2.89125 3.0149 3.638 2.1494 4.575 1.4624L5.45625 2.6624C4.70625 3.2124 4.10925 3.90615 3.66525 4.74365C3.22125 5.58115 2.9995 6.4999 3 7.4999H1.5ZM15 7.4999C15 6.4999 14.778 5.58115 14.334 4.74365C13.89 3.90615 13.2935 3.2124 12.5445 2.6624L13.4257 1.4624C14.3632 2.1499 15.1102 3.01565 15.6667 4.05965C16.2233 5.10365 16.501 6.2504 16.5 7.4999H15Z"
        fill="#3C3C60"
      />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
