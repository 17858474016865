// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input {
  outline: none;
  width: 90%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.custom-input-container {
  width: 100%;
  border: 1px solid #d7d7d7;
  margin: 0 auto;
  border-radius: 10px;
  height: 34px;
  display: flex;
  padding: 2px;
}

.input-title {
  width: 94%;
  margin: 0 auto;
  margin-bottom: 3px;
}

.custom-input-icon-container {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomInput/CustomInput.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,UAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,WAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AAFF;;AAKA;EACE,UAAA;EACA,cAAA;EACA,kBAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAFF","sourcesContent":["@import 'src/common/styles/mixins';\r\n@import 'src/common/styles/variables';\r\n\r\n.custom-input {\r\n  outline: none;\r\n  width: 90%;\r\n  margin: 0 auto;\r\n  padding-left: 15px;\r\n  padding-right: 15px;\r\n  border: none;\r\n}\r\n\r\n.input-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-top: 10px;\r\n}\r\n\r\n.custom-input-container {\r\n  width: 100%;\r\n  border: 1px solid #d7d7d7;\r\n  margin: 0 auto;\r\n  border-radius: 10px;\r\n  height: 34px;\r\n  display: flex;\r\n  padding: 2px;\r\n}\r\n\r\n.input-title {\r\n  width: 94%;\r\n  margin: 0 auto;\r\n  margin-bottom: 3px;\r\n}\r\n\r\n.custom-input-icon-container {\r\n  width: 10%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
