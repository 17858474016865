import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import styles from './Commission.module.scss';

import { routes } from 'enums';
import { LeftArrow, RightArrow, Search, Percents } from 'assets/icons';
import { useActions, useAppSelector, useDebounce } from 'hooks';
import {
  commissionSelect,
  commissionThunk,
  notificationThunk,
  notificationsSelect,
} from 'state';
import { CustomButton } from 'components/CustomButton';
import { NotificationModal } from 'components/NotificationModal';
import clsx from 'clsx';
import ReactRouterPrompt from 'react-router-prompt';
import { WarningModal } from 'components/WarningModal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const CommissionItem = ({ title, value, isEditMode, hanleChange, egp }: any) => {
  return (
    <div className={styles.commissionItemContainer}>
      <div className={styles.commissionItemCategory}>{title}</div>
      <div
        className={styles.commissionItemValue}
        style={
          isEditMode ? { border: '1px solid #00bef2' } : { border: '1px solid #d8d8d8' }
        }
      >
        <input
          type="number"
          inputMode="numeric"
          className={styles.commissionInputValue}
          value={value}
          onChange={e => hanleChange(e.target.value)}
          disabled={!isEditMode}
        />
        <div className={styles.unitContainer}>
          {egp ? <p style={{ color: '#b5b5c2' }}>EGP</p> : <Percents />}
        </div>
      </div>
    </div>
  );
};

export const Commission = () => {
  const navigate = useNavigate();
  const [isNotificationModal, setNotificationModal] = useState(false);
  const { getNotifications, createNotification } = useActions(notificationThunk);
  const { getCommissions, updateCommissions } = useActions(commissionThunk);
  const notifications: any = useAppSelector(notificationsSelect);
  const commissions: any = useAppSelector(commissionSelect);
  const [selectedCategory, setSelectedCategory] = useState('GP/Emergency doctor');
  const [bodyMessage, setBodyMessage] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isWarningModal, setWarningModal] = useState(false);
  const [commissionLocal, setCommissionLocal] = useState({
    gpEmergency: '',
    specialist: '',
    consultant: '',
  });
  const [pricingLocal, setPricingLocal]: any = useState({
    gpEmergency: '',
    specialist: '',
    consultant: '',
    nurse: '',
    physioTherapist: '',
  });
  const [specialist, setSpecialist] = useState('');

  useEffect(() => {
    if (isEditMode) {
      setWarningModal(true);
    }
  }, [isEditMode]);

  // useEffect(() => {
  //   if (isEdit) {
  //     setWarningModal(true);
  //   }
  // }, [isEdit]);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    getCommissions();
  }, []);

  useEffect(() => {
    if (debouncedSearchValue) {
      setIsSearching(true);
      getNotifications({ q: debouncedSearchValue, take: itemPerPage, page: '' });
    } else {
      getNotifications({ q: searchValue, take: itemPerPage, page: '' });
    }
  }, [debouncedSearchValue]);

  const itemPerPage = 3;

  useEffect(() => {
    getNotifications({ q: '', take: itemPerPage, page: '' });
  }, []);

  const getRateValueFromArray = (category: any) => {
    let rate = '';
    commissions.forEach((el: any) => {
      if (el.category === category) {
        rate = el.rate;
      }
    });

    return rate;
  };

  const getPriceValueFromArray = (category: any) => {
    let price = '';
    commissions.forEach((el: any) => {
      if (el.category === category) {
        price = el.price;
      }
    });

    return price;
  };

  useEffect(() => {
    if (commissions[0]) {
      setCommissionLocal({
        gpEmergency: getRateValueFromArray('GP/Emergency doctor'),
        specialist: getRateValueFromArray('Specialist doctor'),
        consultant: getRateValueFromArray('Consultant doctor'),
      });

      setPricingLocal({
        gpEmergency: getPriceValueFromArray('GP/Emergency doctor'),
        specialist: getPriceValueFromArray('Specialist doctor'),
        consultant: getPriceValueFromArray('Consultant doctor'),
        nurse: getPriceValueFromArray('Nurse'),
        physioTherapist: getPriceValueFromArray('Physio therapist'),
      });
    }
  }, [commissions]);

  const handleAdd = () => {
    const data = {
      doctorCategories: selectedCategory,
      body: bodyMessage,
    };

    setSelectedCategory('GP/Emergency doctor');
    setBodyMessage('');
    createNotification(data);
    setNotificationModal(false);
  };

  const handleLeftArrowClick = () => {
    if (notifications?.page > 1) {
      getNotifications({
        q: '',
        take: itemPerPage,
        page: notifications?.page - 1,
      });
    }
  };

  const handleRightArrowClick = () => {
    if (notifications?.page * itemPerPage < notifications.count) {
      getNotifications({
        q: '',
        take: itemPerPage,
        page: notifications?.page + 1,
      });
    }
  };

  const getCommissionItemIdByCategory = (category: any) => {
    let commissionItem = commissions.filter((el: any) => el.category === category);
    console.log(commissionItem);
    return commissionItem[0].id;
  };

  const generateAndUpdateCommissions = () => {
    let t = [
      {
        id: getCommissionItemIdByCategory('Consultant doctor'),
        category: 'Consultant doctor',
        rate: commissionLocal['consultant'],
        price: pricingLocal['consultant'],
      },
      {
        id: getCommissionItemIdByCategory('GP/Emergency doctor'),
        category: 'GP/Emergency doctor',
        rate: commissionLocal['gpEmergency'],
        price: pricingLocal['gpEmergency'],
      },
      {
        id: getCommissionItemIdByCategory('Specialist doctor'),
        category: 'Specialist doctor',
        rate: commissionLocal['specialist'],
        price: pricingLocal['specialist'],
      },
      {
        id: getCommissionItemIdByCategory('Nurse'),
        category: 'Nurse',
        price: pricingLocal['nurse'],
      },
      {
        id: getCommissionItemIdByCategory('Physio therapist'),
        category: 'Physio therapist',
        price: pricingLocal['physioTherapist'],
      },
    ];
    updateCommissions(t);
    setEditMode(false);
  };

  const handlePressButton = () => {
    isEditMode ? generateAndUpdateCommissions() : setEditMode(true);
    if (isEditMode) {
      setWarningModal(false);
    }
  };

  const printRef: any = useRef();

  return (
    <div className={styles.container} ref={printRef}>
      <div className={styles.header}>
        <div className={styles.flex}>
          <div className={styles.title}>Commission rate & pricing</div>
        </div>

        <div className={styles.flex}>
          <div className={styles.buttonContainer}>
            <CustomButton
              dark={!isEditMode}
              title={isEditMode ? 'Save' : 'Edit'}
              handleClick={() => handlePressButton()}
            />
          </div>
        </div>
      </div>

      {isNotificationModal && (
        <NotificationModal setNotificationModal={setNotificationModal} />
      )}

      <div className={styles.table}>
        <div className={styles.tableItem}>
          <h2 className={styles.subtitle}>Commission rates for</h2>
          <CommissionItem
            isEditMode={isEditMode}
            title={'GP/Emergency'}
            value={commissionLocal['gpEmergency']}
            hanleChange={(value: any) => {
              setCommissionLocal({
                ...commissionLocal,
                gpEmergency: value,
              });
            }}
          />
          <CommissionItem
            isEditMode={isEditMode}
            title={'Specialist'}
            value={commissionLocal['specialist']}
            hanleChange={(value: any) => {
              setCommissionLocal({
                ...commissionLocal,
                specialist: value,
              });
            }}
          />
          <CommissionItem
            isEditMode={isEditMode}
            title={'Consultant'}
            value={commissionLocal['consultant']}
            hanleChange={(value: any) => {
              setCommissionLocal({
                ...commissionLocal,
                consultant: value,
              });
            }}
          />
        </div>

        <div className={styles.tableItem}>
          <div>
            <h2 className={styles.subtitle}>Nurses subscription</h2>
            <CommissionItem
              isEditMode={isEditMode}
              egp
              title={'Nurse'}
              value={pricingLocal['nurse']}
              hanleChange={(value: any) => {
                setPricingLocal({
                  ...pricingLocal,
                  nurse: value,
                });
              }}
            />
          </div>
          <div>
            <CommissionItem
              isEditMode={isEditMode}
              egp
              title={'Physio therapist'}
              value={pricingLocal['physioTherapist']}
              hanleChange={(value: any) => {
                setPricingLocal({
                  ...pricingLocal,
                  physioTherapist: value,
                });
              }}
            />
          </div>
        </div>

        <ReactRouterPrompt when={isWarningModal}>
          {({ isActive, onConfirm, onCancel }) => (
            <WarningModal
              onConfirm={onConfirm}
              height={200}
              setWarningModal={setWarningModal}
            />
          )}
        </ReactRouterPrompt>

        <div className={styles.tableItem}>
          <h2 className={styles.subtitle}>Prising</h2>
          <CommissionItem
            egp
            isEditMode={isEditMode}
            title={'GP/Emergency'}
            value={pricingLocal['gpEmergency']}
            hanleChange={(value: any) => {
              setPricingLocal({
                ...pricingLocal,
                gpEmergency: value,
              });
            }}
          />
          <CommissionItem
            egp
            isEditMode={isEditMode}
            title={'Specialist'}
            value={pricingLocal['specialist']}
            hanleChange={(value: any) => {
              setPricingLocal({
                ...pricingLocal,
                specialist: value,
              });
            }}
          />
          <CommissionItem
            egp
            isEditMode={isEditMode}
            title={'Consultant'}
            value={pricingLocal['consultant']}
            hanleChange={(value: any) => {
              setPricingLocal({
                ...pricingLocal,
                consultant: value,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
