import {
  ChangeEvent,
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  useId,
  useState,
} from 'react';

import { clsx } from 'clsx';

import { Typography } from '..';

import './TextField.scss';

import { Eye, EyeInvisible } from 'assets';

export type TextFieldProps = {
  onValueChange?: (value: string) => void;
  containerProps?: ComponentProps<'div'>;
  labelProps?: ComponentProps<'label'>;
  errorMessage?: string;
  label?: string;
} & ComponentPropsWithoutRef<'input'>;

function getFinalType(type: ComponentProps<'input'>['type'], showPassword: boolean) {
  if (type === 'password' && showPassword) {
    return 'text';
  }

  return type;
}
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      errorMessage,
      placeholder,
      type,
      containerProps,
      labelProps,
      label,
      onChange,
      onValueChange,
      ...restProps
    },
    ref,
  ) => {
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    const isShowPasswordButtonShown = type === 'password';

    const finalType = getFinalType(type, showPassword);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      onChange?.(e);
      onValueChange?.(e.target.value);
    }

    const classNames = {
      textField: clsx('textField', containerProps?.className),
      field: clsx('field', !!errorMessage && 'field_error', className),
      label: clsx('label', labelProps?.className),
    };

    return (
      <div className={classNames.textField}>
        {label && (
          <Typography
            variant="p1"
            as="label"
            className={classNames.label}
            htmlFor={`${id}-input`}
          >
            {label}
          </Typography>
        )}
        <div className="fieldContainer">
          <input
            className={classNames.field}
            placeholder={placeholder}
            ref={ref}
            type={finalType}
            onChange={handleChange}
            id={`${id}-input`}
            aria-describedby={`${id}-error-message`}
            {...restProps}
          />
          {isShowPasswordButtonShown && (
            <button
              className="showPassword"
              type="button"
              onClick={() => setShowPassword(prev => !prev)}
            >
              {showPassword ? <Eye /> : <EyeInvisible />}
            </button>
          )}
        </div>
        {errorMessage && (
          <Typography
            variant="p3"
            className="error"
            aria-live="polite"
            id={`${id}-error-message`}
          >
            {errorMessage}
          </Typography>
        )}
      </div>
    );
  },
);
