import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_463_6416)">
      <g clip-path="url(#clip0_463_6416)">
        <rect
          x="4.56958"
          y="3.84033"
          width="22.9605"
          height="22.9605"
          rx="11.4803"
          fill="white"
        />
        <g clip-path="url(#clip1_463_6416)">
          <path d="M14.4102 20.2406V16.5505H17.6902V20.2406H14.4102Z" fill="#FED2A5" />
          <path
            opacity="0.3"
            d="M16.0499 19.4207C14.8199 19.4207 13.8632 18.464 13.5898 18.1907L18.0999 18.6007C17.6899 18.874 16.8699 19.4207 16.0499 19.4207Z"
            fill="black"
          />
          <g filter="url(#filter1_d_463_6416)">
            <ellipse cx="16.05" cy="14.5003" rx="4.92011" ry="4.5101" fill="#FED2A5" />
          </g>
          <path
            d="M24.25 27.6207C24.25 26.5977 24.0379 25.5847 23.6258 24.6396C23.2137 23.6944 22.6096 22.8356 21.8482 22.1123C21.0867 21.3889 20.1828 20.8151 19.1879 20.4236C18.193 20.0321 17.1267 19.8306 16.0498 19.8306C14.9729 19.8306 13.9066 20.0321 12.9117 20.4236C11.9168 20.8151 11.0128 21.3889 10.2514 22.1123C9.48993 22.8356 8.88591 23.6944 8.47381 24.6396C8.06171 25.5847 7.84961 26.5977 7.84961 27.6207L16.0498 27.6207H24.25Z"
            fill="#5CCEC4"
          />
          <path
            d="M12.7695 14.5004C12.3057 14.9643 11.6762 15.4571 11.1295 15.3204C10.5828 13.8171 11.9495 9.58032 16.0496 9.58032C20.1497 9.58032 21.2431 13.1337 20.9697 14.9104C20.6964 14.9104 20.0677 14.8284 19.7397 14.5004C19.4117 14.1724 18.5097 13.2704 18.5097 12.8604H13.5896C13.4529 13.1337 13.1796 14.0904 12.7695 14.5004Z"
            fill="#4B3F4E"
          />
          <path
            d="M13.5894 12.8603C13.2614 12.5323 12.6327 10.2636 12.3594 9.58027C13.1794 9.30693 15.0654 8.76025 16.0495 8.76025C17.2795 8.76025 18.5095 9.58027 18.9195 10.4003C19.2475 11.0563 18.9195 12.3137 18.5095 12.8603C17.1428 12.7237 13.9174 13.1884 13.5894 12.8603Z"
            fill="#4B3F4E"
          />
          <path
            d="M13.5902 20.2405C13.4535 21.4705 13.7542 23.5206 16.0503 23.5206C18.1003 23.5206 18.5103 21.4705 18.5103 20.2405"
            stroke="#3C3C60"
            stroke-width="0.820018"
            stroke-linecap="round"
          />
          <circle cx="20.5601" cy="23.9307" r="1.23003" fill="#D9D9D9" />
          <circle cx="20.5604" cy="23.9308" r="0.410009" fill="white" />
          <path
            d="M16.0501 23.5208C16.0501 23.9308 15.611 26.8008 18.5102 26.8008C20.1502 26.8008 20.5602 25.1608 20.5602 24.3408"
            stroke="#3C3C60"
            stroke-width="0.410009"
            stroke-linecap="round"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_463_6416"
        x="0.742829"
        y="0.970269"
        width="30.614"
        height="30.614"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.956688" />
        <feGaussianBlur stdDeviation="1.91338" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_463_6416"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_463_6416"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_463_6416"
        x="11.1299"
        y="9.99023"
        width="9.84033"
        height="9.02026"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_463_6416"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_463_6416"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_463_6416">
        <rect
          x="4.56958"
          y="3.84033"
          width="22.9605"
          height="22.9605"
          rx="11.4803"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_463_6416">
        <rect
          width="16.4004"
          height="23.7805"
          fill="white"
          transform="translate(7.84961 4.66016)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
