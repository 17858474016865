import axios, { AxiosError } from 'axios';

import { API_URL } from '../shared/api/base';
import { LsKeys } from '../enums';
import { authActions, authThunk, store } from '../state';

export const authServiceClient = axios.create({
  baseURL: API_URL,
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

authServiceClient.interceptors.request.use(
  config => {
    const data = JSON.parse(localStorage.getItem(LsKeys.TOKEN_INFO)!);

    if (data && data.accessToken) {
      config.headers.Authorization = `Bearer ${data.accessToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

// authServiceClient.interceptors.response.use(
//   response => response,
//   async e => {
//     const originalConfig = e.config;
//     if (
//       originalConfig.url !== '/auth/login' &&
//       originalConfig.url !== '/authentification/refresh-token' &&
//       originalConfig.url !== '/authentification/forgot-password' &&
//       e.response
//     ) {
//       if (e.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         try {
//           const data = JSON.parse(localStorage.getItem(LsKeys.TOKEN_INFO)!);

//           // if (data?.refreshToken) {
//           //   const res: any = await refreshTokenRequest({
//           //     refreshToken: data?.refreshToken,
//           //     accessToken: data?.accessToken,
//           //   });

//           //   localStorage.setItem(
//           //     LsKeys.TOKEN_INFO,
//           //     JSON.stringify({
//           //       accessToken: res?.data?.access_token,
//           //       refreshToken: data?.refreshToken,
//           //     }),
//           //   );
//           //   originalConfig.headers.Authorization = `Bearer ${res?.data?.accessToken}`;
//           // }

//           return authServiceClient(originalConfig);
//         } catch (error) {
//           return Promise.reject(error);
//         }
//       }
//       if (e.response.status === 401 && originalConfig._retry) {
//         console.log('i am here');
//         store.dispatch(authActions.setLogOut());
//       }

//       return Promise.reject(e);
//     }
//     return Promise.reject(e);
//   },
// );

// authServiceClient.interceptors.response.use(
//   response => response,
//   async e => {
//     const errorMsg = extractErrorMessage(e);
//     const originalConfig = e.config;

//     if (originalConfig.url !== '/auth/sign-in' && e.response) {
//       if (e.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         try {
//           const data: any = EncryptedStorage.getItem(LsKeys.TOKEN_INFO);
//           let tokens;
//           if (data) {
//             tokens = JSON.parse(data);
//           }

//           // if (tokens?.refreshToken) {
//           //   const res = await refreshTokenRequest(tokens?.refreshToken);

//           //   store.dispatch(
//           //     authActions.setToken({
//           //       accessToken: res?.data?.accessToken,
//           //       refreshToken: tokens?.refreshToken,
//           //     }),
//           //   );
//           //   originalConfig.headers.Authorization = `Bearer ${res?.data?.accessToken}`;
//           // }

//           return authServiceClient(originalConfig);
//         } catch (error) {
//           return Promise.reject(error);
//         }
//       }
//       if (e.response.status === 401 && originalConfig._retry) {
//         return store.dispatch(authThunk.logOut({}));
//       }
//     }
//     store.dispatch(
//       authActions.setNotification({ notification: { message: errorMsg, type: 'error' } }),
//     );
//   },
// );

export const extractErrorMessage = (e: Error | AxiosError | unknown): string | null => {
  if (axios.isCancel(e)) {
    return null;
  }
  if (axios.isAxiosError(e)) {
    const statusCode = e.response?.status;

    if (statusCode) {
      if (e.response?.data?.message === 'Wrong refresh token') {
        return null;
      }
      if (e.response?.data?.error) {
        return e.response?.data?.error;
      }
    }
  } else if (e instanceof Error && e?.message) {
    return e.message;
  }

  return 'Something went wrong';
};
