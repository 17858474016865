import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M2.8 4.75C2.38 4.75 1.99733 4.65667 1.652 4.47C1.316 4.274 1.05 4.00333 0.854 3.658C0.658 3.30333 0.56 2.88333 0.56 2.398C0.56 1.91267 0.658 1.49733 0.854 1.152C1.05933 0.797333 1.33 0.526666 1.666 0.339999C2.01133 0.143999 2.394 0.0459996 2.814 0.0459996C3.234 0.0459996 3.612 0.143999 3.948 0.339999C4.284 0.526666 4.55 0.797333 4.746 1.152C4.942 1.49733 5.04 1.91267 5.04 2.398C5.04 2.88333 4.93733 3.30333 4.732 3.658C4.536 4.00333 4.27 4.274 3.934 4.47C3.598 4.65667 3.22 4.75 2.8 4.75ZM1.932 10L7.63 0.199999H8.806L3.108 10H1.932ZM2.8 3.84C3.12667 3.84 3.41133 3.71867 3.654 3.476C3.906 3.23333 4.032 2.874 4.032 2.398C4.032 1.91267 3.91067 1.54867 3.668 1.306C3.42533 1.054 3.14067 0.928 2.814 0.928C2.48733 0.928 2.19333 1.054 1.932 1.306C1.68 1.54867 1.554 1.91267 1.554 2.398C1.554 2.874 1.68 3.23333 1.932 3.476C2.184 3.71867 2.47333 3.84 2.8 3.84ZM8.036 10.168C7.616 10.168 7.23333 10.0747 6.888 9.888C6.552 9.692 6.286 9.42133 6.09 9.076C5.894 8.72133 5.796 8.30133 5.796 7.816C5.796 7.33067 5.894 6.91533 6.09 6.57C6.29533 6.21533 6.566 5.94467 6.902 5.758C7.238 5.562 7.616 5.464 8.036 5.464C8.456 5.464 8.834 5.562 9.17 5.758C9.506 5.94467 9.772 6.21533 9.968 6.57C10.164 6.91533 10.262 7.33067 10.262 7.816C10.262 8.30133 10.164 8.72133 9.968 9.076C9.772 9.42133 9.506 9.692 9.17 9.888C8.834 10.0747 8.456 10.168 8.036 10.168ZM8.022 9.258C8.358 9.258 8.64733 9.13667 8.89 8.894C9.142 8.65133 9.268 8.292 9.268 7.816C9.268 7.33067 9.14667 6.96667 8.904 6.724C8.66133 6.48133 8.372 6.36 8.036 6.36C7.70933 6.36 7.42 6.48133 7.168 6.724C6.916 6.96667 6.79 7.33067 6.79 7.816C6.79 8.292 6.91133 8.65133 7.154 8.894C7.406 9.13667 7.69533 9.258 8.022 9.258Z"
      fill="#3C3C60"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
