// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_container__\\+yLO2 {
  width: 256px;
  border-right: 1px solid #d8d8d8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Sidebar_logo__z1f2M {
  width: 74px;
  height: 74px;
  margin: 0 auto;
  margin-top: 37px;
}

.Sidebar_buttonContainer__iYuyQ {
  width: 90%;
  margin-left: 5%;
  bottom: 20px;
  position: absolute;
}

.Sidebar_itemsContainer__tYOjg {
  margin-top: 60px;
  position: relative;
}

@media only screen and (max-width: 1250px) {
  .Sidebar_container__\\+yLO2 {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,+BAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;AAFF;;AAKA;EACE,UAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,kBAAA;AAFF;;AAKA;EACE;IACE,aAAA;EAFF;AACF","sourcesContent":["@import 'src/common/styles/variables';\r\n@import 'src/common/styles/mixins';\r\n\r\n.container {\r\n  width: 256px;\r\n  border-right: 1px solid $color1;\r\n  height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  position: relative;\r\n}\r\n\r\n.logo {\r\n  width: 74px;\r\n  height: 74px;\r\n  margin: 0 auto;\r\n  margin-top: 37px;\r\n}\r\n\r\n.buttonContainer {\r\n  width: 90%;\r\n  margin-left: 5%;\r\n  bottom: 20px;\r\n  position: absolute;\r\n}\r\n\r\n.itemsContainer {\r\n  margin-top: 60px;\r\n  position: relative;\r\n}\r\n\r\n@media only screen and (max-width: 1250px) {\r\n  .container {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Sidebar_container__+yLO2`,
	"logo": `Sidebar_logo__z1f2M`,
	"buttonContainer": `Sidebar_buttonContainer__iYuyQ`,
	"itemsContainer": `Sidebar_itemsContainer__tYOjg`
};
export default ___CSS_LOADER_EXPORT___;
