import React, { useEffect } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Typography } from 'ui-kit';
import { useActions, useAppSelector } from 'hooks';
import { accessTokenSelect, authThunk, isLoadingSelect } from 'state';
import { routes } from 'enums';

export const PublicLayout = () => {
  const location = useLocation();
  const { getMe } = useActions(authThunk);
  const token = useAppSelector(accessTokenSelect);
  const isLoading = useAppSelector(isLoadingSelect);
  const { hash, pathname, search } = location;

  useEffect(() => {
    getMe();
  }, []);

  console.log(pathname);

  if (isLoading) return <></>;

  console.log(token);

  return token ? (
    <Navigate to={routes.MAIN.DASHBOARD} replace state={location} />
  ) : (
    <>
      <Outlet />
    </>
  );
};
