import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePasswordRequest,
  createArticleRequest,
  createNotificationRequest,
  editFinanceRequest,
  editUserRequest,
  getArticleRequest,
  getCommissionsRequest,
  getDoctorFinancesRequest,
  getDoctorsRequest,
  getDoctorVisitsRequest,
  getFinancesRequest,
  getMeRequest,
  getNotificationRequest,
  getPatientVisitsRequest,
  getUserRequest,
  getUsersRequest,
  logInRequest,
  LoginRequestType,
  restorePasswordRequest,
  signUpRequest,
  SignUpRequestType,
  updateCommissionsRequest,
  verifyResetRequest,
} from 'api';

export const getCommissions = createAsyncThunk(
  'commission/get',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getCommissionsRequest();

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const updateCommissions = createAsyncThunk(
  'commission/update',
  async (data: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await updateCommissionsRequest(data);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
    }
  },
);

export const commissionThunk = {
  getCommissions,
  updateCommissions,
};
