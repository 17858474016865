import { AxiosResponse } from 'axios';

import {
  CommonResponseType,
  LoginRequestType,
  LoginResponseType,
  SignUpRequestType,
  UserType,
} from './types';

import { authServiceClient } from 'config';

export const logInRequest = (data: LoginRequestType) => {
  return authServiceClient.post<
    LoginResponseType,
    AxiosResponse<LoginResponseType>,
    LoginRequestType
  >(`/auth/sign-in`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getNotificationsRequest = ({ take, page, q }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/notification/all-admin?q=${q}&take=${take}&page=${page}`,
  );
};

export const getNotificationRequest = (id: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(`/notification/${id}`);
};

export const deleteNotificationRequest = (id: any) => {
  return authServiceClient.delete<any, AxiosResponse<any>>(`/notification/${id}`);
};

export const getNotificationsForAdminRequest = (id: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(`/notification/for-admin/${id}`);
};

export const getUsersRequest = ({ take, page, q, status, category, isVerify }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/users?role=User&q=${q}&take=${take}&page=${page}&status=${status}`,
  );
};

export const getDoctorsRequest = ({ take, page, q, status, category, isVerify }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/users?role=Doctor%2fNurse&q=${q}&take=${take}&page=${page}&status=${status}&category=${category}&isVerify=${isVerify}`,
  );
};

export const getFinancesRequest = ({ take, page, q, status, category }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/finance/all?q=${q}&take=${take}&page=${page}&status=${status}&category=${category}`,
  );
};

export const getArticleRequest = ({ take, page, q }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/articles/all?q=${q}&take=${take}&page=${page}`,
  );
};

export const getArticleByIdRequest = (id: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(`/articles/${id}`);
};

export const deleteArticleByIdRequest = (id: any) => {
  return authServiceClient.delete<any, AxiosResponse<any>>(`/articles/${id}`);
};

export const updateArticleByIdRequest = (id: any, data: any) => {
  return authServiceClient.patch<any, AxiosResponse<any>>(`/articles/${id}`, data);
};

export const getNewcomersRequest = (month: any, year: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/users/statistics/?month=${month}&year=${year}`,
  );
};

export const getActiveUsersRequest = (month: any, year: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `usersActivities?month=${month}&year=${year}`,
  );
};

export const getBlockedUsersRequest = (month: any, year: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `usersBlocked?month=${month}&year=${year}`,
  );
};

export const getFinanceStatisticsRequest = (year: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `finance/statisticsByYear/${year}`,
  );
};

export const getCommissionsRequest = () => {
  return authServiceClient.get<any, AxiosResponse<any>>(`/commission/all`);
};

export const updateCommissionsRequest = (data: any) => {
  return authServiceClient.put<any, AxiosResponse<any>>(`/commission`, data);
};

export const createArticleRequest = (data: any) => {
  return authServiceClient.post<any, AxiosResponse<any>, any>(`/articles/create`, data);
};

export const getUserRequest = (id: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(`/users/${id}`);
};

export const getContactRequest = () => {
  return authServiceClient.get<any, AxiosResponse<any>>(`/contactUs`);
};

export const updateContactRequest = (data: any) => {
  return authServiceClient.patch<any, AxiosResponse<any>>(`/contactUs`, data);
};

export const getPatientVisitsRequest = ({ q, take, page, id, year, month }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/visits/${id}?q=${q}&take=${take}&page=${page}&year=${year}&month=${month}`,
  );
};

export const getDoctorVisitsRequest = ({ q, take, page, id, year, month }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/visits/doctor/${id}?q=${q}&take=${take}&page=${page}&year=${year}&month=${month}`,
  );
};

export const getDoctorFinancesRequest = ({ q, take, page, id, year }: any) => {
  return authServiceClient.get<any, AxiosResponse<any>>(
    `/finance/${id}?q=${q}&take=${take}&page=${page}&year=${year}`,
  );
};

export const editUserRequest = (id: any, data: any) => {
  return authServiceClient.patch<any, AxiosResponse<any>>(`/users/${id}`, data);
};

export const editFinanceRequest = (id: any, data: any) => {
  return authServiceClient.patch<any, AxiosResponse<any>>(`/finance/${id}`, data);
};

export const createNotificationRequest = (data: any) => {
  return authServiceClient.post<any, AxiosResponse<any>, any>(
    `/notification/create`,
    data,
  );
};

export const updateNotificationeByIdRequest = (id: any, data: any) => {
  return authServiceClient.patch<any, AxiosResponse<any>>(`/notification/${id}`, data);
};

export const signUpRequest = (data: SignUpRequestType) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    SignUpRequestType
  >(`/auth/sign-up`, data);
};

export const getMeRequest = () => {
  return authServiceClient.get<UserType, AxiosResponse<UserType>>(`/auth/me`);
};

export const restorePasswordRequest = (data: any) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    any
  >(`/auth/restore-password`, data);
};

export const verifyResetRequest = (data: any) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    any
  >(`/auth/verify-reset`, data);
};

export const refreshTokenRequest = (token: string) => {
  return authServiceClient.get<
    { accessToken: string },
    AxiosResponse<{ accessToken: string }>,
    { token: string }
  >(`/auth/refresh`, {
    headers: { refresh: token },
  });
};

export const changePasswordRequest = (data: any) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    any
  >(`/auth/reset-password`, data);
};
