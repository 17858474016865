import { z } from 'zod';

const WRONG_PASSWORD = 'Password must contain from 8 to 16 characters';
const PASSWORDS_NO_MATCH = "Your passwords don't match";
const WRONG_NAME = 'Name must contain min 2 characters';
const REQUIRED_FIELD = 'Required field';

export const loginSchema = () => {
  return z.object({
    email: z.string().trim().email({ message: 'wrong-email' }),
    password: z
      .string()
      .min(8, { message: 'wrong-password' })
      .max(16, { message: 'wrong-password' }),
  });
};

export const signUpSchema = z
  .object({
    email: z.string().trim().email(),
    name: z.string().trim().min(2, { message: WRONG_NAME }),
    password: z
      .string()
      .min(8, { message: WRONG_PASSWORD })
      .max(16, { message: WRONG_PASSWORD }),
    confirmPassword: z.string().min(1, { message: REQUIRED_FIELD }),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        message: PASSWORDS_NO_MATCH,
        code: z.ZodIssueCode.custom,
        path: ['confirmPassword'],
      });
    }

    return data;
  });

export const nameSchema = z.object({
  name: z.string().trim().min(2, { message: WRONG_NAME }),
});
