import React, { useState } from 'react';

import './CustomInput.scss';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeInvisible } from 'assets';

interface ICustomInputProps {
  title: string;
  clearError: () => void;
  error: string | null;
  value: string;
  onChange: (str: string) => void;
  password?: boolean;
  disabled?: boolean;
}

export const CustomInput = ({
  disabled,
  title,
  clearError,
  error,
  value,
  onChange,
  password,
}: ICustomInputProps) => {
  const navigate = useNavigate();
  const [isVisiblePassword, setVisiblePassword] = useState(false);

  return (
    <div className="input-container">
      <div className="input-title">{title}</div>
      <div
        className="custom-input-container"
        style={error ? { border: '1px solid red' } : {}}
      >
        <input
          type={password ? (isVisiblePassword ? 'text' : 'password') : 'text'}
          onFocus={() => clearError()}
          className="custom-input"
          value={value}
          disabled={disabled}
          onChange={(e: any) => onChange(e.target.value)}
        />
        {password && (
          <div
            className="custom-input-icon-container"
            onClick={() => setVisiblePassword(!isVisiblePassword)}
          >
            {isVisiblePassword ? <Eye /> : <EyeInvisible />}
          </div>
        )}
      </div>
    </div>
  );
};
