import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getActiveUsersRequest,
  getBlockedUsersRequest,
  getFinanceStatisticsRequest,
  getNewcomersRequest,
} from 'api';

export const getNewcomers = createAsyncThunk(
  'statistics/getNewcomers',
  async ({ month, year }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getNewcomersRequest(month, year);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getActiveUsers = createAsyncThunk(
  'statistics/getActiveUsers',
  async ({ month, year }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getActiveUsersRequest(month, year);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getBlockedUsers = createAsyncThunk(
  'statistics/getBlockedUsers',
  async ({ month, year }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getBlockedUsersRequest(month, year);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getFinanceStatistics = createAsyncThunk(
  'statistics/getFinanceStatistics',
  async (year: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getFinanceStatisticsRequest(year);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const statisticsThunk = {
  getNewcomers,
  getActiveUsers,
  getBlockedUsers,
  getFinanceStatistics,
};
