import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 6.25C12.5 4.86929 13.6193 3.75 15 3.75C16.3807 3.75 17.5 4.86929 17.5 6.25C20.4252 7.63319 22.3483 10.5178 22.5 13.75V17.5C22.6908 19.076 23.6186 20.4678 25 21.25H5C6.38139 20.4678 7.30923 19.076 7.5 17.5V13.75C7.65171 10.5178 9.57479 7.63319 12.5 6.25"
      stroke="#3C3C60"
      stroke-width="1.62105"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.25 21.25V22.5C11.25 24.5711 12.9289 26.25 15 26.25C17.0711 26.25 18.75 24.5711 18.75 22.5V21.25"
      stroke="#3C3C60"
      stroke-width="1.62105"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.2499 8.40875C25.43 6.62532 24.2394 5.03712 22.7574 3.75"
      stroke="#3C3C60"
      stroke-width="1.62105"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.75 8.40875C4.56919 6.62555 5.75894 5.03737 7.24 3.75"
      stroke="#3C3C60"
      stroke-width="1.62105"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
