// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomNavItem_container__2XjCd {
  display: flex;
  align-items: center;
  height: 38px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
  cursor: pointer;
}

.CustomNavItem_text__muTKR {
  padding-left: 20px;
  color: #808080;
}

.CustomNavItem_active__JZlGr {
  color: #3c3c60;
}

.CustomNavItem_highlighting__dy7kQ {
  width: 4px;
  height: 38px;
  border-radius: 10px;
  background-color: #00b8ea;
  position: absolute;
  right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomNavItem/CustomNavItem.module.scss","webpack://./src/common/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;AAFF;;AAKA;EACE,kBAAA;EACA,cCbO;ADWT;;AAKA;EACE,cClBO;ADgBT;;AAKA;EACE,UAAA;EACA,YAAA;EACA,mBAAA;EACA,yBCvBO;EDwBP,kBAAA;EACA,UAAA;AAFF","sourcesContent":["@import 'src/common/styles/mixins';\r\n@import 'src/common/styles/variables';\r\n\r\n.container {\r\n  display: flex;\r\n  align-items: center;\r\n  height: 38px;\r\n  width: 70%;\r\n  margin: 0 auto;\r\n  margin-bottom: 20px;\r\n  cursor: pointer;\r\n}\r\n\r\n.text {\r\n  padding-left: 20px;\r\n  color: $color3;\r\n}\r\n\r\n.active {\r\n  color: $color2;\r\n}\r\n\r\n.highlighting {\r\n  width: 4px;\r\n  height: 38px;\r\n  border-radius: 10px;\r\n  background-color: $color4;\r\n  position: absolute;\r\n  right: 6px;\r\n}\r\n","$color1: #d8d8d8;\r\n$color2: #3c3c60;\r\n$color3: #808080;\r\n$color4: #00b8ea;\r\n$color5: #ffffff;\r\n$color6: #bebebe;\r\n$color7: #0149ad;\r\n$color8: #dbe0e4;\r\n$color9: #c5c4cf;\r\n\r\n$textColor-l1: #0a142f;\r\n$textColor-l2: #666;\r\n$textColor-l3: #040404;\r\n\r\n$backgroundColor-l1: #ffffff;\r\n$backgroundColor-l2: #111;\r\n$backgroundColor-l3: #d2d2d2;\r\n\r\n$lineColor-l1: #9fb4cd;\r\n$lineColor-l2: #333;\r\n\r\n$errorColor-l1: #ff4b55;\r\n$successColor: #34a853;\r\n$linkColor: #4f72ee;\r\n$iconColor: rgb(132, 138, 155);\r\n\r\n// font Family\r\n$font-l1: 'Poppins';\r\n\r\n// font sizes\r\n$fontSize-xs: 0.875rem;\r\n$fontSize-s: 1rem;\r\n$fontSize-m: 1.25rem;\r\n$fontSize-l: 2.25rem;\r\n$fontSize-xl: 3.25rem;\r\n\r\n// line heights\r\n$lineHeight-s: 1rem;\r\n$lineHeight-m: 1.5rem;\r\n$lineHeight-l: 2rem;\r\n\r\n// font weights\r\n$fontWeight-s: 400;\r\n$fontWeight-m: 500;\r\n$fontWeight-l: 600;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CustomNavItem_container__2XjCd`,
	"text": `CustomNavItem_text__muTKR`,
	"active": `CustomNavItem_active__JZlGr`,
	"highlighting": `CustomNavItem_highlighting__dy7kQ`
};
export default ___CSS_LOADER_EXPORT___;
