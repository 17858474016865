import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="1">
      <path
        d="M2.5 3.3335V15.0002C2.5 15.4422 2.67559 15.8661 2.98816 16.1787C3.30072 16.4912 3.72464 16.6668 4.16667 16.6668H15.8333C16.2754 16.6668 16.6993 16.4912 17.0118 16.1787C17.3244 15.8661 17.5 15.4422 17.5 15.0002V6.66683H14.1667"
        stroke={props.active ? 'black' : '#808080'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 3.3335H14.1667V15.0002C14.1667 15.4422 14.3423 15.8661 14.6548 16.1787C14.9674 16.4912 15.3913 16.6668 15.8333 16.6668M10.8333 6.66683H5.83333M10.8333 10.0002H7.5"
        stroke={props.active ? 'black' : '#808080'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
