import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePasswordRequest,
  createNotificationRequest,
  editUserRequest,
  getDoctorFinancesRequest,
  getDoctorsRequest,
  getDoctorVisitsRequest,
  getMeRequest,
  getNotificationRequest,
  getPatientVisitsRequest,
  getUserRequest,
  getUsersRequest,
  logInRequest,
  LoginRequestType,
  restorePasswordRequest,
  signUpRequest,
  SignUpRequestType,
  verifyResetRequest,
} from 'api';
import { LsKeys, routes } from 'enums';
import { authActions } from 'state';

export const getDoctors = createAsyncThunk(
  'doctors/get',
  async (query: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getDoctorsRequest(query);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getDoctor = createAsyncThunk(
  'user/get',
  async (id: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getUserRequest(id);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const editUser = createAsyncThunk(
  'user/patch',
  async ({ data, id }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await editUserRequest(id, data);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getDoctorVisits = createAsyncThunk(
  'visits/get',
  async (query: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getDoctorVisitsRequest(query);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getDoctorFinances = createAsyncThunk(
  'finances/get',
  async (query: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getDoctorFinancesRequest(query);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const doctorThunk = {
  getDoctors,
  getDoctor,
  editUser,
  getDoctorVisits,
  getDoctorFinances,
};
