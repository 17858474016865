import React, { useState } from 'react';
import styled from 'styled-components';
import styles from './Menu.module.scss';
import { CustomNavItem } from '../CustomNavItem';
import { useNavigate } from 'react-router-dom';
import { SidebarItems, routes } from 'enums';
import {
  Dashboard,
  Finance,
  Reminder,
  Union,
  Users,
  Notification,
  Commission,
  Info,
} from 'assets/icons';

const StyledMenu = styled.nav`
  background: white;
  border-right: 1px solid black;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  padding: 0 2rem 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
    //width: 50vw;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`;

const Menu = ({ open }) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(SidebarItems.DAILY_USERS_REMINDER);

  return (
    <StyledMenu open={open}>
      <div className={styles.itemsContainer}>
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.DASHBOARD);
            setSelectedItem(SidebarItems.DASHBOARD);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.DASHBOARD}
          icon={<Dashboard active={selectedItem === SidebarItems.DASHBOARD} />}
        />
        <CustomNavItem
          onSelect={() => {
            setSelectedItem(SidebarItems.USERS);
            navigate(routes.MAIN.USERS);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.USERS}
          icon={<Users active={selectedItem === SidebarItems.USERS} />}
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.DOCTORS);
            setSelectedItem(SidebarItems.DOCTORS_NURSES);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.DOCTORS_NURSES}
          icon={<Union active={selectedItem === SidebarItems.DOCTORS_NURSES} />}
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.FINANCES);
            setSelectedItem(SidebarItems.FINANCE);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.FINANCE}
          icon={<Finance active={selectedItem === SidebarItems.FINANCE} />}
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.ARTICLES);
            setSelectedItem(SidebarItems.DAILY_USERS_REMINDER);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.DAILY_USERS_REMINDER}
          icon={<Reminder active={selectedItem === SidebarItems.DAILY_USERS_REMINDER} />}
        />
        <CustomNavItem
          onSelect={() => {
            setSelectedItem(SidebarItems.URGENT_NOTIFICATION);
            navigate(routes.MAIN.NOTIFICATION);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.URGENT_NOTIFICATION}
          icon={
            <Notification active={selectedItem === SidebarItems.URGENT_NOTIFICATION} />
          }
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.COMMISSION);
            setSelectedItem(SidebarItems.COMMISSION_RATES_PRICING);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.COMMISSION_RATES_PRICING}
          icon={
            <Commission active={selectedItem === SidebarItems.COMMISSION_RATES_PRICING} />
          }
        />
      </div>
    </StyledMenu>
  );
};

export default Menu;
