// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__4yy6p {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  margin: auto;
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oCAAA;EACA,aAAA;EACA,YAAA;EACA,MAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".loader {\r\n  position: absolute;\r\n  background-color: rgba(0, 0, 0, 0.7);\r\n  height: 100vh;\r\n  width: 100vw;\r\n  top: 0;\r\n  display: flex;\r\n  margin: auto;\r\n  z-index: 25;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__4yy6p`
};
export default ___CSS_LOADER_EXPORT___;
