import { combineReducers } from '@reduxjs/toolkit';
import {
  articleReducer,
  authReducer,
  commissionReducer,
  doctorReducer,
  financeReducer,
  notificationReducer,
  statisticsReducer,
  userReducer,
} from './reducers';
import { contactUsReducer } from './reducers/contactUsSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationReducer,
  users: userReducer,
  doctors: doctorReducer,
  finances: financeReducer,
  articles: articleReducer,
  statistics: statisticsReducer,
  contacts: contactUsReducer,
  commissions: commissionReducer,
});
