import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.86001 3.07323L1.21335 12.4999C1.09693 12.7015 1.03533 12.9301 1.03467 13.1629C1.03402 13.3957 1.09434 13.6246 1.20963 13.8269C1.32492 14.0292 1.49116 14.1977 1.69182 14.3158C1.89247 14.4339 2.12055 14.4973 2.35335 14.4999H13.6467C13.8795 14.4973 14.1076 14.4339 14.3082 14.3158C14.5089 14.1977 14.6751 14.0292 14.7904 13.8269C14.9057 13.6246 14.966 13.3957 14.9654 13.1629C14.9647 12.9301 14.9031 12.7015 14.7867 12.4999L9.14001 3.07323C9.02117 2.8773 8.85383 2.71531 8.65414 2.60288C8.45446 2.49046 8.22917 2.4314 8.00001 2.4314C7.77086 2.4314 7.54557 2.49046 7.34588 2.60288C7.1462 2.71531 6.97886 2.8773 6.86001 3.07323V3.07323Z"
      stroke="#FFB82E"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 6.5V9.16667"
      stroke="#FFB82E"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 11.8334H8.0075"
      stroke="#FFB82E"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
