import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LsKeys } from 'enums';
import {
  createNotification,
  getNotifications,
} from 'state/thunks/notificationThunk/notificationThunk';
import {
  editUser,
  getPatientVisits,
  getUser,
  getUsers,
} from 'state/thunks/userThunk/userThunk';

const initialState = {
  users: {},
  count: null,
  lastPage: null,
  page: null,
  user: null,
  isLoading: false,
  visits: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogOut: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(getPatientVisits.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get user success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.visits = action.payload;
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get user success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.user = action.payload;
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get user success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.users = action.payload;
        state.count = action.payload.count;
        state.lastPage = action.payload.lastPage;
        state.page = action.payload.page;
      })
      .addCase(editUser.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(editUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(editUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getUsers.rejected, (state, action: PayloadAction<any>) => {
        //state.error = action.payload;
        //state.isLoading = false;
      });
  },
});

export const userActions = slice.actions;

export const userReducer = slice.reducer;
