import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_463_6494)">
      <rect
        x="4.51465"
        y="3.66016"
        width="22.9605"
        height="22.9605"
        rx="11.4803"
        fill="#0149AD"
      />
      <path
        d="M14.8335 20.8806V17.0858L11.4159 18.9832L10.2546 17.0539L13.6722 15.1405L10.2546 13.2431L11.4159 11.3138L14.8335 13.2112V9.40039H17.1561V13.2112L20.5736 11.3138L21.7349 13.2431L18.3174 15.1405L21.7349 17.0539L20.5736 18.9832L17.1561 17.0858V20.8806H14.8335Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_463_6494"
        x="0.687897"
        y="0.790093"
        width="30.614"
        height="30.614"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.956688" />
        <feGaussianBlur stdDeviation="1.91338" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_463_6494"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_463_6494"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
