// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_container__H5mzR {
  z-index: 1;
  width: calc(100vw - 250px);
  height: 60px;
  z-index: 9;
  position: absolute;
  right: 0;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px;
}

.Header_icon__mpGem {
  cursor: pointer;
  margin-right: 18px;
}

.Header_redEllipse__b9ew0 {
  position: absolute;
  top: 7px;
  padding-left: 18px;
}

.Header_notificationModalContainer__bLS3g {
  width: 310px;
  right: 80px;
  height: 317px;
  background-color: white;
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  overflow: auto;
  padding: 10px 0px;
}

.Header_notificationItemContainer__zxlTl {
  width: 100%;
  min-height: 62px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.Header_notificationItemIcon__BLMU3 {
  padding: 10px 14px;
}

.Header_notificationItemBody__wZSuS {
  font-weight: 600;
}

.Header_notificationItemDate__5Rc22 {
  color: #b4b6c0;
  margin-top: 2px;
  font-size: 14px;
}

@media only screen and (max-width: 1250px) {
  .Header_container__H5mzR {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAGA;EACE,UAAA;EACA,0BAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,eAAA;EACA,kBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,kBAAA;AAFF;;AAKA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,uCAAA;EACA,cAAA;EACA,iBAAA;AAFF;;AAKA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,cAAA;EACA,eAAA;EACA,eAAA;AAFF;;AAKA;EACE;IACE,YAAA;EAFF;AACF","sourcesContent":["@import 'src/common/styles/mixins';\r\n@import 'src/common/styles/variables';\r\n\r\n.container {\r\n  z-index: 1;\r\n  width: calc(100vw - 250px);\r\n  height: 60px;\r\n  z-index: 9;\r\n  position: absolute;\r\n  right: 0;\r\n  border-bottom: 1px solid $color1;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-end;\r\n  padding-right: 25px;\r\n}\r\n\r\n.icon {\r\n  cursor: pointer;\r\n  margin-right: 18px;\r\n}\r\n\r\n.redEllipse {\r\n  position: absolute;\r\n  top: 7px;\r\n  padding-left: 18px;\r\n}\r\n\r\n.notificationModalContainer {\r\n  width: 310px;\r\n  right: 80px;\r\n  height: 317px;\r\n  background-color: white;\r\n  position: absolute;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);\r\n  overflow: auto;\r\n  padding: 10px 0px;\r\n}\r\n\r\n.notificationItemContainer {\r\n  width: 100%;\r\n  min-height: 62px;\r\n  display: flex;\r\n  align-items: center;\r\n  margin: 10px 0;\r\n}\r\n\r\n.notificationItemIcon {\r\n  padding: 10px 14px;\r\n}\r\n\r\n.notificationItemBody {\r\n  font-weight: 600;\r\n}\r\n\r\n.notificationItemDate {\r\n  color: #b4b6c0;\r\n  margin-top: 2px;\r\n  font-size: 14px;\r\n}\r\n\r\n@media only screen and (max-width: 1250px) {\r\n  .container {\r\n    width: 100vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Header_container__H5mzR`,
	"icon": `Header_icon__mpGem`,
	"redEllipse": `Header_redEllipse__b9ew0`,
	"notificationModalContainer": `Header_notificationModalContainer__bLS3g`,
	"notificationItemContainer": `Header_notificationItemContainer__zxlTl`,
	"notificationItemIcon": `Header_notificationItemIcon__BLMU3`,
	"notificationItemBody": `Header_notificationItemBody__wZSuS`,
	"notificationItemDate": `Header_notificationItemDate__5Rc22`
};
export default ___CSS_LOADER_EXPORT___;
