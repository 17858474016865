import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LsKeys } from 'enums';
import { createArticle, getArticles } from 'state/thunks/articleThunk/articleThunk';
import {
  getActiveUsers,
  getBlockedUsers,
  getFinanceStatistics,
  getNewcomers,
} from 'state/thunks/statisticsThunk/statisticsThunk';

const initialState = {
  newcomers: {},
  active: {},
  blocked: {},
  finances: [],
};

const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setLogOut: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(getNewcomers.fulfilled, (state, action: PayloadAction<any>) => {
      state.newcomers = action.payload;
    });
    builder.addCase(getActiveUsers.fulfilled, (state, action: PayloadAction<any>) => {
      state.active = action.payload;
    });
    builder.addCase(getBlockedUsers.fulfilled, (state, action: PayloadAction<any>) => {
      state.blocked = action.payload;
    });
    builder.addCase(
      getFinanceStatistics.fulfilled,
      (state, action: PayloadAction<any>) => {
        console.log('----a-aaaaaaa----');
        console.log(action.payload);
        state.finances = action.payload;
      },
    );
  },
});

export const statisticsActions = slice.actions;

export const statisticsReducer = slice.reducer;
