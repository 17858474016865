import React from 'react';

import styles from './CustomButton.module.scss';
import { Info } from 'assets';
import clsx from 'clsx';

interface ICustomButtonProps {
  title: string;
  handleClick: () => void;
  isIcon?: boolean;
  dark?: boolean;
  disabled?: boolean;
}

export const CustomButton = ({
  title,
  handleClick,
  isIcon,
  dark,
  disabled,
}: ICustomButtonProps) => {
  return (
    <div
      style={disabled ? { backgroundColor: 'gray', cursor: 'auto' } : {}}
      className={clsx(dark ? [styles.container, styles.dark] : styles.container)}
      onClick={disabled ? () => {} : handleClick}
    >
      {isIcon && (
        <div className={styles.iconContainer}>
          <Info />
        </div>
      )}
      {title}
    </div>
  );
};
