import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.6 6.0001C3.6 5.36358 3.85285 4.75313 4.30294 4.30304C4.75303 3.85295 5.36348 3.6001 6 3.6001H16.0548C16.6913 3.60023 17.3016 3.85318 17.7516 4.3033L19.6968 6.2485C20.1469 6.69848 20.3999 7.30883 20.4 7.9453V18.0001C20.4 18.6366 20.1471 19.2471 19.6971 19.6972C19.247 20.1472 18.6365 20.4001 18 20.4001H6C5.36348 20.4001 4.75303 20.1472 4.30294 19.6972C3.85285 19.2471 3.6 18.6366 3.6 18.0001V6.0001ZM6 4.8001C5.68174 4.8001 5.37651 4.92653 5.15147 5.15157C4.92643 5.37661 4.8 5.68184 4.8 6.0001V18.0001C4.8 18.3184 4.92643 18.6236 5.15147 18.8486C5.37651 19.0737 5.68174 19.2001 6 19.2001V13.8001C6 13.3227 6.18964 12.8649 6.52721 12.5273C6.86477 12.1897 7.32261 12.0001 7.8 12.0001H16.2C16.6774 12.0001 17.1352 12.1897 17.4728 12.5273C17.8104 12.8649 18 13.3227 18 13.8001V19.2001C18.3183 19.2001 18.6235 19.0737 18.8485 18.8486C19.0736 18.6236 19.2 18.3184 19.2 18.0001V7.9453C19.1999 7.62706 19.0735 7.32189 18.8484 7.0969L16.9032 5.1517C16.6782 4.92664 16.373 4.80017 16.0548 4.8001H15.6V7.8001C15.6 8.27749 15.4104 8.73532 15.0728 9.07289C14.7352 9.41046 14.2774 9.6001 13.8 9.6001H9C8.52261 9.6001 8.06477 9.41046 7.72721 9.07289C7.38964 8.73532 7.2 8.27749 7.2 7.8001V4.8001H6ZM8.4 4.8001V7.8001C8.4 7.95923 8.46321 8.11184 8.57573 8.22436C8.68826 8.33688 8.84087 8.4001 9 8.4001H13.8C13.9591 8.4001 14.1117 8.33688 14.2243 8.22436C14.3368 8.11184 14.4 7.95923 14.4 7.8001V4.8001H8.4ZM16.8 19.2001V13.8001C16.8 13.641 16.7368 13.4884 16.6243 13.3758C16.5117 13.2633 16.3591 13.2001 16.2 13.2001H7.8C7.64087 13.2001 7.48826 13.2633 7.37573 13.3758C7.26321 13.4884 7.2 13.641 7.2 13.8001V19.2001H16.8Z"
      fill="#141C35"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
