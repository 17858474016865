import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import styles from './Dashboard.module.scss';

import { routes } from 'enums';
import { LeftArrow, RightArrow, Search, TableViewOpen } from 'assets/icons';
import { useActions, useAppSelector, useDebounce } from 'hooks';
import {
  doctorThunk,
  notificationThunk,
  notificationsSelect,
  statisticsThunk,
  userSelect,
} from 'state';
import { CustomButton } from 'components/CustomButton';
import { NotificationModal } from 'components/NotificationModal';
import clsx from 'clsx';
import { userThunk } from 'state/thunks/userThunk/userThunk';
import { usersSelect } from 'state/selectors/userSelectors';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { doctorsSelect } from 'state/selectors/doctorSelectors';
import { stat } from 'fs/promises';
import { CustomDropdown } from 'ui-kit/CustomDropdown';
import { financeThunk } from 'state/thunks/financeThunk/financeThunk';
import { financesSelect } from 'state/selectors/financeSelectors';
import User from 'assets/icons/User';
import Nurse from 'assets/icons/Nurse';
import Specialist from 'assets/icons/Specialist';
import Emergency from 'assets/icons/Emergency';
import Consultant from 'assets/icons/Consultant';
import { LineChart } from '@mui/x-charts/LineChart';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  activeUsersSelect,
  blockedUsersSelect,
  financesStatisticsSelect,
  newcomersSelect,
} from 'state/selectors/statisticsSelectors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const NotificationItem = ({ user, columns, columnWidth }: any) => {
  const itemsObj: any = {
    'Doctor name': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.name}
      </div>
    ),
    'Specialist Type': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.category}
      </div>
    ),
    Speciality: (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.speciality}
      </div>
    ),
    'For month': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {'???'}
      </div>
    ),
    Amount: (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.amount}
      </div>
    ),
    'Due date': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {'???'}
      </div>
    ),
    'Payment status': (
      <div
        className={styles.customCol1}
        style={{ width: columnWidth + '%', fontWeight: 'bold' }}
      >
        {user.paymentStatus}
      </div>
    ),
    Status: (
      <div
        className={styles.customCol1}
        id="Status"
        style={
          user.user.doctor.status
            ? { color: 'green', fontWeight: 'bold' }
            : { color: 'red', fontWeight: 'bold' }
        }
      >
        {user.user.doctor.status ? 'Active' : 'Blocked'}
      </div>
    ),
  };

  return (
    <div className={styles.tableItem}>
      {columns.map((column: any) => {
        if (column.isSelect) {
          return itemsObj[column.name];
        }
      })}
    </div>
  );
};

const defaultColumns = [
  {
    name: 'Doctor name',
    isSelect: true,
  },
  {
    name: 'Specialist Type',
    isSelect: true,
  },
  {
    name: 'Speciality',
    isSelect: true,
  },
  {
    name: 'For month',
    isSelect: true,
  },
  {
    name: 'Amount',
    isSelect: true,
  },
  {
    name: 'Due date',
    isSelect: true,
  },
  {
    name: 'Payment status',
    isSelect: true,
  },
  {
    name: 'Status',
    isSelect: true,
  },
];

const NewcomersItem = ({ icon, title, count }: any) => {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.flex}>
        {icon}
        <div className={styles.itemTitle}>{title}</div>
      </div>
      <div className={styles.itemCount}>{count}</div>
    </div>
  );
};

export const options = {
  scales: {
    y: {
      min: 0,
      max: 3000,
      ticks: {
        stepSize: 500,
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: '',
    },
  },
};

const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
const data1 = [100, 500, 400, 350, 700, 100, 200, 250, 300, 250];
const data2 = [300, 200, 300, 550, 200, 300, 600, 400, 500];

export const data = {
  labels,
  datasets: [
    {
      label: '',
      display: false,
      data: data1.map(el => el),
      borderColor: '#4BA16E',
      backgroundColor: '#4BA16E',
    },
    {
      label: '',
      data: data2.map(el => el),
      borderColor: '#F05763',
      backgroundColor: '#F05763',
    },
  ],
};

export const Dashboard = () => {
  const navigate = useNavigate();
  const [isNotificationModal, setNotificationModal] = useState(false);
  const { getUsers } = useActions(userThunk);
  const { getDoctors } = useActions(doctorThunk);
  const { getNewcomers, getActiveUsers, getBlockedUsers, getFinanceStatistics } =
    useActions(statisticsThunk);
  const { getFinances } = useActions(financeThunk);
  const users: any = useAppSelector(usersSelect);
  const doctors: any = useAppSelector(doctorsSelect);
  const newcomers: any = useAppSelector(newcomersSelect);
  const finacnesStatisitcs: any = useAppSelector(financesStatisticsSelect);
  const blockedUsers: any = useAppSelector(blockedUsersSelect);
  const activeUsers: any = useAppSelector(activeUsersSelect);
  const finances: any = useAppSelector(financesSelect);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [bodyMessage, setBodyMessage] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);
  const [columnWidth, setColumnWidth] = useState(100);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedMonthNewcomers, setSelectedMonthNewcomers] = useState('');
  const [selectedMonthActive, setSelectedMonthActive] = useState('');
  const [selectedMonthBanned, setSelectedMonthBanned] = useState('');
  const [selectedYearFinance, setSelectedYearFinance] = useState('2024');
  const [financeDataProfit, setFinanceDataProfit] = useState(new Array(10).fill(0));
  const [financeDataNoPaid, setFinanceDataNoPaid] = useState(new Array(10).fill(0));

  const getFinanceData = () => {
    return {
      labels,
      datasets: [
        {
          label: '',
          display: false,
          data: financeDataProfit.map(el => el),
          borderColor: '#4BA16E',
          backgroundColor: '#4BA16E',
        },
        {
          label: '',
          data: financeDataNoPaid.map(el => el),
          borderColor: '#F05763',
          backgroundColor: '#F05763',
        },
      ],
    };
  };

  useEffect(() => {
    console.log('----------');
    console.log(financeDataProfit);
  }, [financeDataProfit]);

  const handleColumnChange = (name: any) => {
    console.log(name);
    setColumns(
      columns.map(el => {
        if (el.name === name) {
          return {
            ...el,
            isSelect: !el.isSelect,
          };
        } else {
          return el;
        }
      }),
    );
  };

  // useEffect(() => {
  //   getColumnWidth();
  // }, [columns]);

  useEffect(() => {
    getFinanceStatistics(selectedYearFinance);
  }, [selectedYearFinance]);

  useEffect(() => {
    const columnCount = getColumnWidth();
    setColumnWidth(columnCount);
  }, [columns]);

  const getColumnWidth = () => {
    const columnCount = columns.reduce((accumulator: any, el: any) => {
      if (el.isSelect) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    console.log(columnCount);

    return 100 / columnCount;
  };

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const convertStatusValue = () => {
    switch (statusValue) {
      case 'active':
        return true;
        break;
      case 'blocked':
        return false;
        break;
      case '':
        return '';
    }
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      setIsSearching(true);
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        category: selectedCategory,
        status: convertStatusValue(),
      });
    } else {
      getFinances({
        q: searchValue,
        take: itemPerPage,
        page: '',
        category: selectedCategory,
        status: convertStatusValue(),
      });
    }
  }, [debouncedSearchValue]);

  const itemPerPage = 2;

  useEffect(() => {
    getUsers({ q: '', take: itemPerPage, page: '' });
    //getNewcomers(new Date().getMonth() + 1);
    getFinanceStatistics(2024);
    setSelectedMonthNewcomers(String(new Date().getMonth() + 1));
    // getBlockedUsers(new Date().getMonth() + 1);
    // getActiveUsers(new Date().getMonth() + 1);
    getFinances({
      q: '',
      take: itemPerPage,
      page: '',
      category: selectedCategory,
      status: convertStatusValue(),
    });
  }, []);

  useEffect(() => {
    getFinances({
      q: debouncedSearchValue,
      take: itemPerPage,
      page: '',
      category: selectedCategory,
      status: convertStatusValue(),
    });
  }, [selectedCategory]);

  useEffect(() => {
    console.log('finacnesStatisitcs change');
    console.log(finacnesStatisitcs);

    let financeDataProfit = new Array(10).fill(0);
    let financeDataNoPaid = new Array(10).fill(0);

    finacnesStatisitcs.forEach((el: any) => {
      financeDataProfit[el.month] = el.profit;
      financeDataNoPaid[el.month] = el.noPaid;
    });

    setFinanceDataProfit(financeDataProfit);
    setFinanceDataNoPaid(financeDataNoPaid);
  }, [finacnesStatisitcs]);

  const handleLeftArrowClick = () => {
    if (finances?.page > 1) {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: finances?.page - 1,
        category: selectedCategory,
        status: convertStatusValue(),
      });
    }
  };

  const handleRightArrowClick = () => {
    if (finances?.page * itemPerPage < finances.count) {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: finances?.page + 1,
        category: selectedCategory,
        status: convertStatusValue(),
      });
    }
  };

  useEffect(() => {
    if (statusValue === '') {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        category: selectedCategory,
      });
    }

    if (statusValue === 'active') {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        status: convertStatusValue(),
        category: selectedCategory,
      });
    }
    if (statusValue === 'blocked') {
      getFinances({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
        status: convertStatusValue(),
        category: selectedCategory,
      });
    }
  }, [statusValue]);

  useEffect(() => {
    console.log('!!!!!!!!!!!!!!');
    console.log(!!selectedMonthNewcomers);
    getNewcomers({ month: selectedMonthNewcomers, year: selectedYearFinance });
    getBlockedUsers({ month: selectedMonthNewcomers, year: selectedYearFinance });
    getActiveUsers({ month: selectedMonthNewcomers, year: selectedYearFinance });
  }, [selectedMonthNewcomers, selectedYearFinance]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>Dashboard</div>
      <div className={styles.header}>
        <div className={styles.flex}>
          <div className={styles.title}>Newcomers</div>
        </div>

        <div className={styles.flex}>
          <FormControl sx={{ m: 1, minWidth: 140 }}>
            <Select
              value={selectedMonthNewcomers}
              onChange={e => {
                setSelectedMonthNewcomers(e.target.value);
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ height: 40, padding: 0 }}
            >
              <MenuItem value={''}>Month: all</MenuItem>
              {months.map((el, index) => (
                <MenuItem value={index + 1}>Month: {el}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <CustomDropdown columns={columns} handleColumnChange={handleColumnChange} /> */}
          <FormControl sx={{ m: 1, minWidth: 140 }}>
            <Select
              value={selectedYearFinance}
              onChange={e => {
                setSelectedYearFinance(e.target.value);
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ height: 40, padding: 0 }}
            >
              <MenuItem value={'2024'}>Year: {2024}</MenuItem>
              <MenuItem value={'2025'}>Year: {2025}</MenuItem>
              <MenuItem value={'2026'}>Year: {2026}</MenuItem>
              <MenuItem value={'2027'}>Year: {2027}</MenuItem>
              <MenuItem value={'2028'}>Year: {2024}</MenuItem>
              <MenuItem value={'2029'}>Year: {2025}</MenuItem>
              <MenuItem value={'2030'}>Year: {2026}</MenuItem>
              <MenuItem value={'2031'}>Year: {2027}</MenuItem>
              <MenuItem value={'2032'}>Year: {2026}</MenuItem>
              <MenuItem value={'2033'}>Year: {2027}</MenuItem>
              <MenuItem value={'2034'}>Year: {2027}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={styles.dashboardItemsContainer}>
        <NewcomersItem title={'New Users'} count={newcomers['user']} icon={<User />} />
        <NewcomersItem title={'New Nurses'} count={newcomers['Nurse']} icon={<Nurse />} />
        <NewcomersItem
          title={'New Specialist Doctors'}
          count={newcomers['Specialist doctor']}
          icon={<Specialist />}
        />
        <NewcomersItem
          title={'New Emergency Doctors'}
          count={newcomers['GP/Emergency doctor']}
          icon={<Emergency />}
        />
        <NewcomersItem
          title={'New Consultant Doctors'}
          count={newcomers['Consultant doctor']}
          icon={<Consultant />}
        />
      </div>

      <div className={styles.header} style={{ margin: '20px 0px' }}>
        <div className={styles.flex}>
          <div className={styles.title}>Active</div>
        </div>

        <div className={styles.flex}>
          {/* <CustomDropdown columns={columns} handleColumnChange={handleColumnChange} /> */}
          {/* <FormControl sx={{ m: 1, minWidth: 140 }}>
            <Select
              value={selectedMonthActive}
              onChange={e => {
                setSelectedMonthActive(e.target.value);
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ height: 40, padding: 0 }}
            >
              <MenuItem value={''}>Month: all</MenuItem>
              {months.map((el, index) => (
                <MenuItem value={index}>Month: {el}</MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </div>
      </div>

      <div className={styles.dashboardItemsContainer}>
        <NewcomersItem title={'Users'} count={activeUsers['user']} icon={<User />} />
        <NewcomersItem title={'Nurses'} count={activeUsers['Nurse']} icon={<Nurse />} />
        <NewcomersItem
          title={'Specialist Doctors'}
          count={activeUsers['Specialist doctor']}
          icon={<Specialist />}
        />
        <NewcomersItem
          title={'Emergency Doctors'}
          count={activeUsers['GP/Emergency doctor']}
          icon={<Emergency />}
        />
        <NewcomersItem
          title={'Consultant Doctors'}
          count={activeUsers['Consultant doctor']}
          icon={<Consultant />}
        />
      </div>

      <div className={styles.header} style={{ margin: '20px 0px' }}>
        <div className={styles.flex}>
          <div className={styles.title}>Banned</div>
        </div>

        <div className={styles.flex}>
          {/* <CustomDropdown columns={columns} handleColumnChange={handleColumnChange} /> */}
          {/* <FormControl sx={{ m: 1, minWidth: 140 }}>
            <Select
              value={selectedMonthBanned}
              onChange={e => {
                setSelectedMonthBanned(e.target.value);
              }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ height: 40, padding: 0 }}
            >
              <MenuItem value={''}>Month: all</MenuItem>
              {months.map((el, index) => (
                <MenuItem value={index}>Month: {el}</MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </div>
      </div>

      <div className={styles.dashboardItemsContainer}>
        <NewcomersItem title={'Users'} count={blockedUsers['user']} icon={<User />} />
        <NewcomersItem title={'Nurses'} count={blockedUsers['Nurse']} icon={<Nurse />} />
        <NewcomersItem
          title={'Specialist Doctors'}
          count={blockedUsers['Specialist doctor']}
          icon={<Specialist />}
        />
        <NewcomersItem
          title={'Emergency Doctors'}
          count={blockedUsers['GP/Emergency doctor']}
          icon={<Emergency />}
        />
        <NewcomersItem
          title={' Consultant Doctors'}
          count={blockedUsers['Consultant doctor']}
          icon={<Consultant />}
        />
      </div>

      <div className={styles.header}>
        <div className={styles.flex}>
          <div className={styles.title}>Finance</div>
        </div>
      </div>

      <div
        className={styles.financeContainer}
        style={{ justifyContent: 'space-between', paddingBottom: 20 }}
      >
        <div className={styles.financeTable}>
          <div className={styles.financeTableHeader}>
            <div className={styles.financeTableItem}>Month</div>
            <div className={styles.financeTableItem}>No paid</div>
            <div className={styles.financeTableItem}>Profit</div>
          </div>
          {finacnesStatisitcs.map((el: any) => {
            return (
              <div className={styles.financeTableItemsContainer}>
                <div className={styles.financeTableItem}>
                  {months[el.month] + ',' + selectedYearFinance}
                </div>
                <div className={styles.financeTableItem} style={{ color: '#F05763' }}>
                  -${el.noPaid}
                </div>
                <div className={styles.financeTableItem} style={{ color: '#4BA16E' }}>
                  +${el.profit}
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.financeTable}>
          <Line options={options} data={getFinanceData()} />
        </div>
      </div>

      {isNotificationModal && (
        <NotificationModal setNotificationModal={setNotificationModal} />
      )}
    </div>
  );
};
