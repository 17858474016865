import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LsKeys } from 'enums';
import {
  createArticle,
  deleteArticle,
  getArticle,
  getArticles,
  updateArticle,
} from 'state/thunks/articleThunk/articleThunk';

const initialState = {
  doctors: null,
  doctor: null,
  count: null,
  lastPage: null,
  page: null,
  user: null,
  isLoading: false,
  visits: [],
  articles: [],
  article: null,
};

const slice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    clearArticle: state => {
      state.article = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(getArticles.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload);
        state.articles = action.payload;
        state.count = action.payload.count;
        state.lastPage = action.payload.lastPage;
        state.page = action.payload.page;
      })
      .addCase(getArticle.fulfilled, (state, action: PayloadAction<any>) => {
        state.article = action.payload;
      })
      .addCase(createArticle.fulfilled, (state, action: PayloadAction<any>) => {
        state.articles = action.payload;
        state.count = action.payload.count;
        state.lastPage = action.payload.lastPage;
        state.page = action.payload.page;
      })
      .addCase(deleteArticle.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload);
        console.log(state.articles);
        state.articles.rows = state.articles.rows.filter(
          (el: any) => el.id !== action.payload,
        );
      })
      .addCase(updateArticle.fulfilled, (state, action: PayloadAction<any>) => {
        state.articles.rows = state.articles.rows.map((el: any) =>
          el.id === action.payload.id ? action.payload : el,
        );
      });
  },
});

export const articleActions = slice.actions;

export const articleReducer = slice.reducer;
