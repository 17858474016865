import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserType } from 'api';
import { NotificationType } from 'common';
import { LsKeys } from 'enums';
import { getMe, login, restorePassword } from 'state/thunks/authThunk/authThunk';

const initialState = {
  initialized: false as boolean,
  accessToken: '' as string,
  error: '' as string,
  isLoading: true,
  userInfo: {} as UserType,
  language: 'English',
  resetToken: '' as string,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogOut: () => initialState,
    clearError: state => {
      state.error = '';
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.accessToken = action.payload.accessToken;
        state.userInfo = action.payload.user;
        state.verified = true;

        const data = {
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
        };

        localStorage.setItem(LsKeys.TOKEN_INFO, JSON.stringify(data));
        state.isLoading = false;
      })
      .addCase(login.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getMe.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
        state.verified = true;
      })
      .addCase(getMe.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get me ok');
        console.log(state.userInfo);
        console.log(action.payload);
        state.userInfo = {
          ...state.userInfo,
          ...action.payload,
          isLoading: false,
        };
        state.accessToken = true;
        state.isLoading = false;
      })
      .addCase(getMe.rejected, (state, action: PayloadAction<any>) => {
        console.log('get me rejected');
        state.isLoading = false;
      })
      .addCase(restorePassword.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('restore password success');
        console.log(action.payload);
        state.resetToken = action.payload.payload;
        alert(action.payload.code);
        state.isLoading = false;
      })
      .addCase(restorePassword.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(restorePassword.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const authActions = slice.actions;

export const authReducer = slice.reducer;
