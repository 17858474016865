import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="94"
    height="94"
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6667 86.1668V82.2502C15.6667 74.926 15.6667 71.2678 17.2412 68.5418C18.2724 66.7557 19.7556 65.2726 21.5417 64.2413C24.2677 62.6668 27.9259 62.6668 35.25 62.6668L47 78.3335L58.75 62.6668C66.0742 62.6668 69.7323 62.6668 72.4583 64.2413C74.2444 65.2726 75.7276 66.7557 76.7588 68.5418C78.3333 71.2678 78.3333 74.926 78.3333 82.2502V86.1668M62.4199 31.3335L66.4071 15.1342C67.3197 11.4251 64.5584 7.8335 60.7984 7.8335H33.2016C29.4416 7.8335 26.6804 11.4251 27.5929 15.1342L31.5801 31.3335M62.4199 31.3335V39.1668C62.4199 47.8187 55.5188 54.8335 47 54.8335C38.4813 54.8335 31.5801 47.8187 31.5801 39.1668V31.3335M62.4199 31.3335H31.5801M47 15.6668V23.5002M50.9167 19.5835H43.0834"
      stroke="#9DA6B5"
      stroke-width="3.54717"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.4528 45.2262C44.5036 44.0919 45.9419 43.4526 47.4434 43.4526C48.9449 43.4526 50.3831 44.0919 51.434 45.2262"
      stroke="#9DA6B5"
      stroke-width="3.54717"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
