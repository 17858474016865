import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 9.39062H10.25V16.695H9V9.39062ZM12.75 9.39062H14V16.695H12.75V9.39062Z"
      fill="#848A9B"
    />
    <path
      d="M4 4.77112V6.11274H5.25V19.5289C5.25 19.8847 5.3817 20.2259 5.61612 20.4775C5.85054 20.7292 6.16848 20.8705 6.5 20.8705H16.5C16.8315 20.8705 17.1495 20.7292 17.3839 20.4775C17.6183 20.2259 17.75 19.8847 17.75 19.5289V6.11274H19V4.77112H4ZM6.5 19.5289V6.11274H16.5V19.5289H6.5ZM9 2.08789H14V3.42951H9V2.08789Z"
      fill="#848A9B"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
