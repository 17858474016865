import React, { ReactChild, ReactFragment, ReactPortal } from 'react';

import styles from './ModalLayout.module.scss';
import ReactDOM from 'react-dom';
import CrossIcon from 'assets/icons/CrossIcon';

type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;

interface IModalLayoutProps {
  children: ReactNode;
  handleClose: () => void;
  title: string;
  height?: any;
}

export const ModalLayout = ({
  title,
  handleClose,
  children,
  height,
}: IModalLayoutProps) => {
  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.modal}>
        <div
          onClick={() => {
            handleClose();
            // setNotificationModal(false);
            // setSelectedCategory('GP/Emergency doctor');
            // setBodyMessage('');
          }}
          className={styles.crossIconContainer}
        >
          <CrossIcon />
        </div>
        <div className={styles.title}>{title}</div>
        {children}
      </div>
    </div>,
    document.body,
  );
};
