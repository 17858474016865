import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 12.5L12.5 7.5"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="7.91667"
      cy="7.91667"
      r="0.416667"
      fill={props.active ? '#3C3C60' : '#808080'}
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="12.0834"
      cy="12.0832"
      r="0.416667"
      fill={props.active ? '#3C3C60' : '#808080'}
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.16656 5.9999C4.16656 4.98737 4.98737 4.16656 5.9999 4.16656H6.83323C7.31731 4.16629 7.78164 3.97456 8.1249 3.63323L8.70823 3.0499C9.05234 2.70385 9.52021 2.50928 10.0082 2.50928C10.4962 2.50928 10.9641 2.70385 11.3082 3.0499L11.8916 3.63323C12.2348 3.97456 12.6991 4.16629 13.1832 4.16656H14.0166C15.0291 4.16656 15.8499 4.98737 15.8499 5.9999V6.83323C15.8502 7.31731 16.0419 7.78164 16.3832 8.1249L16.9666 8.70823C17.3126 9.05234 17.5072 9.52021 17.5072 10.0082C17.5072 10.4962 17.3126 10.9641 16.9666 11.3082L16.3832 11.8916C16.0419 12.2348 15.8502 12.6991 15.8499 13.1832V14.0166C15.8499 15.0291 15.0291 15.8499 14.0166 15.8499H13.1832C12.6991 15.8502 12.2348 16.0419 11.8916 16.3832L11.3082 16.9666C10.9641 17.3126 10.4962 17.5072 10.0082 17.5072C9.52021 17.5072 9.05234 17.3126 8.70823 16.9666L8.1249 16.3832C7.78164 16.0419 7.31731 15.8502 6.83323 15.8499H5.9999C4.98737 15.8499 4.16656 15.0291 4.16656 14.0166V13.1832C4.16629 12.6991 3.97456 12.2348 3.63323 11.8916L3.0499 11.3082C2.70385 10.9641 2.50928 10.4962 2.50928 10.0082C2.50928 9.52021 2.70385 9.05234 3.0499 8.70823L3.63323 8.1249C3.97456 7.78164 4.16629 7.31731 4.16656 6.83323V5.9999"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
