export enum DoctorSpecialities {
  CARDIOLOGY = 'Cardiology',
  DENTIST = 'Dentist',
  ENT = 'ENT',
  SURGERY = 'SURGERY',
  INTERNAL_MEDICINE = 'Internal medicine',
  GASTROENTEROLOGY = 'Gastroenterology',
  PULMONOLOGY = 'Pulmonology',
  NEUROLOGY = 'Neurology',
  OBST_GYN = 'Obst.&Gyn',
  OPHTHALMOLOGY = 'Ophthalmology',
  ORTHOPEDICS = 'Orthopedics',
  PEDIATRIC = 'Pediatric',
  UROLOGY = 'Rheumatology',
  PSYCHIATRIC = 'Psychiatric and addiction treatment',
}
