import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ModalLayout } from 'layouts/ModalLayout/ModalLayout';
import { useActions, useAppSelector } from 'hooks';
import { articleThunk, contactSelect, contactUsThunk, notificationThunk } from 'state';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import styles from './ContactUsModal.module.scss';

const ItemInput = ({ title, value, handleChange, isEditMode }: any) => {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.title}>{title}</div>
      <input
        disabled={!isEditMode}
        className={styles.inputContainer}
        value={value}
        onChange={handleChange}
        style={isEditMode ? { border: '1px solid #00bef2' } : {}}
      />
    </div>
  );
};

interface IContactModalProps {
  setContactModal: (value: boolean) => void;
}

export const ContactUsModal = ({ setContactModal }: IContactModalProps) => {
  const [bodyMessage, setBodyMessage] = useState('');
  const { updateContact, getContact } = useActions(contactUsThunk);
  const [value, onChange] = useState<any>(new Date());
  const [isEditMode, setEditMode] = useState(false);
  const contacts: any = useAppSelector(contactSelect);
  const [body, setBody] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [wa, setWa] = useState('');
  const [website, setWebsite] = useState('');
  const [facebook, setFacebook] = useState('');

  const handleSave = () => {
    updateContact({
      body,
      phone1,
      phone2,
      email1,
      email2,
      wa,
      website,
      facebook,
    });
    handleClose();
  };

  const handleClick = () => {
    isEditMode ? handleSave() : setEditMode(true);
  };

  const handleAdd = () => {
    const data = {
      publishingDate: value,
      content: bodyMessage,
    };

    setBodyMessage('');
    setContactModal(false);
  };

  const handleClose = () => {
    setContactModal(false);
    setBodyMessage('');
  };

  useEffect(() => {
    getContact();
  }, []);

  useEffect(() => {
    console.log(contacts);
    if (contacts) {
      setBody(contacts.body);
      setPhone1(contacts.phone1);
      setPhone2(contacts.phone2);
      setEmail1(contacts.email1);
      setEmail2(contacts.email2);
      setWa(contacts.wa);
      setWebsite(contacts.website);
      setFacebook(contacts.facebook);
    }
  }, [contacts]);

  return (
    <ModalLayout handleClose={handleClose} title={'Contact us info'}>
      <div style={{ height: 20 }}></div>
      <ItemInput
        handleChange={(e: any) => {
          setBody(e.target.value);
        }}
        isEditMode={isEditMode}
        title={'Contact us body'}
        value={body}
      />
      <ItemInput
        isEditMode={isEditMode}
        title={'Phone#1'}
        value={phone1}
        handleChange={(e: any) => {
          setPhone1(e.target.value);
        }}
      />
      <ItemInput
        isEditMode={isEditMode}
        title={'Email#1'}
        value={email1}
        handleChange={(e: any) => {
          setEmail1(e.target.value);
        }}
      />
      <ItemInput
        isEditMode={isEditMode}
        title={'WA'}
        value={wa}
        handleChange={(e: any) => {
          setWa(e.target.value);
        }}
      />
      <ItemInput
        isEditMode={isEditMode}
        title={'Website'}
        value={website}
        handleChange={(e: any) => {
          setWebsite(e.target.value);
        }}
      />
      <ItemInput
        isEditMode={isEditMode}
        title={'Facebook'}
        value={facebook}
        handleChange={(e: any) => {
          setFacebook(e.target.value);
        }}
      />
      <div
        className={styles.buttonDark}
        style={isEditMode ? {} : { backgroundColor: '#00BEF2' }}
        onClick={handleClick}
      >
        {isEditMode ? 'Save' : 'Edit'}
      </div>
    </ModalLayout>
  );
};
