import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <mask id="path-1-inside-1_463_7280" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99999 17H12.5V12.25H17.5V7.74998H12.5V3H7.99999L7.99999 7.74998H3V12.25H7.99999L7.99999 17Z"
      />
    </mask>
    <path
      d="M12.5 17V18H13.5V17H12.5ZM7.99999 17H6.99999V18H7.99999V17ZM12.5 12.25V11.25H11.5V12.25H12.5ZM17.5 12.25V13.25H18.5V12.25H17.5ZM17.5 7.74998H18.5V6.74998H17.5V7.74998ZM12.5 7.74998H11.5V8.74998H12.5V7.74998ZM12.5 3H13.5V2H12.5V3ZM7.99999 3V2H6.99999V3L7.99999 3ZM7.99999 7.74998V8.74998H8.99999V7.74998H7.99999ZM3 7.74998V6.74998H2V7.74998H3ZM3 12.25H2V13.25H3V12.25ZM7.99999 12.25H8.99999V11.25H7.99999V12.25ZM12.5 16H7.99999V18H12.5V16ZM11.5 12.25V17H13.5V12.25H11.5ZM17.5 11.25H12.5V13.25H17.5V11.25ZM16.5 7.74998V12.25H18.5V7.74998H16.5ZM12.5 8.74998H17.5V6.74998H12.5V8.74998ZM11.5 3V7.74998H13.5V3H11.5ZM7.99999 4H12.5V2H7.99999V4ZM8.99999 7.74998L8.99999 3L6.99999 3L6.99999 7.74998H8.99999ZM3 8.74998H7.99999V6.74998H3V8.74998ZM4 12.25V7.74998H2V12.25H4ZM7.99999 11.25H3V13.25H7.99999V11.25ZM8.99999 17L8.99999 12.25H6.99999L6.99999 17H8.99999Z"
      fill={props.active ? '#3C3C60' : '#808080'}
      mask="url(#path-1-inside-1_463_7280)"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
