import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ModalLayout } from 'layouts/ModalLayout/ModalLayout';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useActions, useAppDispatch, useAppSelector } from 'hooks';
import { notificationActions, notificationSelect, notificationThunk } from 'state';
import styles from './NotificationModal.module.scss';
import { Warning } from 'assets';

interface INotificationModalProps {
  setNotificationModal: (value: boolean) => void;
  isEdit?: boolean;
}

export const NotificationModal = ({
  setNotificationModal,
  isEdit,
}: INotificationModalProps) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('GP/Emergency doctor');
  const [bodyMessage, setBodyMessage] = useState('');
  const [bodyMessageArabic, setBodyMessageArabic] = useState('');
  const { getNotifications, createNotification, updateNotification } =
    useActions(notificationThunk);
  const [isEnglish, setIsEnglish] = useState(true);
  const [isWarning, setWarning] = useState(false);
  const notification: any = useAppSelector(notificationSelect);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (notification) {
      setBodyMessage(notification.contentEnglish);
      setBodyMessageArabic(notification.contentArabic);
      setSelectedCategory(notification.category);
    }
  }, [notification]);

  const handleChangeLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  const handleAdd = () => {
    console.log('handleAdd');
    if (!bodyMessage || !bodyMessageArabic) {
      setWarning(true);
      return;
    }

    const data = {
      doctorCategories: selectedCategory,
      contentEnglish: bodyMessage,
      contentArabic: bodyMessageArabic,
    };

    setSelectedCategory('GP/Emergency doctor');
    setBodyMessage('');
    isEdit ? updateNotification({ id: notification.id, data }) : createNotification(data);
    setNotificationModal(false);
    dispatch(notificationActions.clearNotification());
  };

  const handleClose = () => {
    setNotificationModal(false);
    setSelectedCategory('GP/Emergency doctor');
    setBodyMessage('');
    dispatch(notificationActions.clearNotification());
  };

  return (
    <ModalLayout handleClose={handleClose} title={'Create Notification'}>
      <div className={styles.radiobuttonContainer}>
        <div
          onClick={handleChangeLanguage}
          className={isEnglish ? styles.checkedLanguage : styles.uncheckedLanguage}
        >
          English
        </div>
        <div
          onClick={handleChangeLanguage}
          className={!isEnglish ? styles.checkedLanguage : styles.uncheckedLanguage}
        >
          Arabic
        </div>
      </div>

      <div>
        <p>User type</p>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCategory}
            label="Category"
            onChange={e => {
              setSelectedCategory(e.target.value);
            }}
          >
            <MenuItem value={'GP/Emergency doctor'}>GP/Emergency doctor</MenuItem>
            <MenuItem value={'Specialist doctor'}>Specialist doctor</MenuItem>
            <MenuItem value={'Nurse'}>Nurse</MenuItem>
            <MenuItem value={'Consultant doctor'}>Consultant doctor</MenuItem>
            <MenuItem value={'Physio therapist'}>Physio therapist</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <p>Notification body</p>
        <textarea
          maxLength={100}
          className={styles.notificationModalTextarea}
          value={isEnglish ? bodyMessage : bodyMessageArabic}
          onChange={(e: any) => {
            isEnglish
              ? setBodyMessage(e.target.value)
              : setBodyMessageArabic(e.target.value);
          }}
        />
      </div>

      {isWarning && (
        <div className={styles.warningContainer}>
          <Warning />
          <div className={styles.warningText}>Both languages ​​must be filled in</div>
        </div>
      )}

      <div className="button-dark" onClick={handleAdd}>
        {isEdit ? 'Save' : 'Add'}
      </div>
    </ModalLayout>
  );
};
