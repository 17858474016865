import { lazy } from 'react';

import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { PrivateLayout, PublicLayout } from 'components';
import { routes } from 'enums';
import ResetPassword from 'pages/ResetPassword';
import ChangePassword from 'pages/ChangePassword';
import Users from 'pages/Users';
import Finances from 'pages/Finances';
import Dashboard from 'pages/Dashboard';
import Articles from 'pages/Articles';
import { Commission } from 'pages/Commission/Commission';

const Main = lazy(() => import('pages/Main'));
const Notification = lazy(() => import('pages/Notification'));
const User = lazy(() => import('pages/User'));
const Doctor = lazy(() => import('pages/Doctor'));
const Doctors = lazy(() => import('pages/Doctors'));
const Login = lazy(() => import('pages/Login'));

const publicRoutes: RouteObject[] = [
  { path: routes.AUTH.LOG_IN, element: <Login /> },
  { path: routes.AUTH.RESET_PASSWORD, element: <ResetPassword /> },
  { path: routes.AUTH.CHANGE_PASSWORD, element: <ChangePassword /> },
];

const privateRoutes: RouteObject[] = [
  { path: '*', element: <Navigate to={routes.MAIN.DASHBOARD} /> },

  { path: `${routes.MAIN.NOTIFICATION}`, element: <Notification /> },
  { path: `${routes.MAIN.USERS}`, element: <Users /> },
  { path: `${routes.MAIN.DOCTORS}`, element: <Doctors /> },
  { path: `${routes.MAIN.USER}/:id`, element: <User /> },
  { path: `${routes.MAIN.DOCTOR}/:id`, element: <Doctor /> },
  { path: `${routes.MAIN.FINANCES}`, element: <Finances /> },
  { path: `${routes.MAIN.ARTICLES}`, element: <Articles /> },
  { path: `${routes.MAIN.COMMISSION}`, element: <Commission /> },
  { path: `${routes.MAIN.DASHBOARD}`, element: <Dashboard /> },
];

const router = createBrowserRouter([
  { element: <PublicLayout />, children: publicRoutes },
  { element: <PrivateLayout />, children: privateRoutes },
]);

export const Router = () => {
  return <RouterProvider router={router} />;
};
