import React, { useEffect, useState } from 'react';

import './ResetPassword.scss';

import { AuthFormLayout } from 'layouts/AuthFormLayout/AuthFormLayout';
import logo from 'assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { routes } from 'enums';
import { CustomInput } from 'components/CustomInput';
import { useActions, useAppDispatch, useAppSelector } from 'hooks';
import {
  accessTokenSelect,
  authActions,
  authThunk,
  errorSelect,
  resetTokenSelect,
} from 'state';

export const ResetPassword = () => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  //const [error, setError] = useState('');
  const { login } = useActions(authThunk);
  const token = useAppSelector(accessTokenSelect);
  const error = useAppSelector(errorSelect);
  const dispatch = useAppDispatch();
  const { restorePassword } = useActions(authThunk);
  const { verifyReset } = useActions(authThunk);
  const resetToken: string = useAppSelector(resetTokenSelect);

  const handleSubmit = async () => {
    const verifyData = {
      token: resetToken,
      code: +code,
    };
    await verifyReset({ verifyData, navigate });
    //await restorePassword({ restoreData: { phone: '555' } });
    // if (checkIsGoodStatus(res?.payload?.status)) {
    //   navigate(routes.MAIN.INIT);
    // }
    // if (email === 'admin@gmail.com' && password === '123456') {
    //   navigate(routes.MAIN.INIT);
    // } else {
    //   setError('Wrong credentials');
    // }
  };

  useEffect(() => {
    if (token) {
      navigate(routes.MAIN.INIT);
    }
  }, [token]);

  const handleResendCode = () => {};

  return (
    <AuthFormLayout
      title={'Reset password'}
      subtitle={'Enter the confirmation code we sent to your email'}
      onSubmit={handleSubmit}
      handleResendResetClick={handleResendCode}
      bottomText={'Resend code'}
      isArrow
    >
      <div style={{ width: '70%', margin: '0 auto' }}>
        <CustomInput
          error={error}
          clearError={() => dispatch(authActions.clearError())}
          title={'Confirmation code'}
          value={code}
          onChange={setCode}
        />
      </div>

      <div
        style={{
          height: 26,
          fontSize: 15,
          color: 'red',
          //bottom: 20,
          margin: '0 auto',
          //marginTop: 5,
        }}
      >
        {error}
      </div>
    </AuthFormLayout>
  );
};
