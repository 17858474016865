import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15.0011"
      cy="12.6141"
      r="4.54474"
      stroke="#3C3C60"
      stroke-width="1.62105"
    />
    <path
      d="M21.5474 25.1091C21.5474 21.1656 18.617 17.9688 15.0021 17.9688C11.3872 17.9688 8.45679 21.1656 8.45679 25.1091"
      stroke="#3C3C60"
      stroke-width="1.62105"
    />
    <circle
      cx="15.0014"
      cy="14.9937"
      r="11.6851"
      stroke="#3C3C60"
      stroke-width="1.62105"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
