import { CircularProgress } from '@mui/material';
import styles from './Loader.module.scss';

import { useAppSelector } from 'hooks';
import { isLoadingSelect } from 'state';

export const Loader = () => {
  const isLoading = useAppSelector(isLoadingSelect);

  return isLoading ? (
    <div className={styles.loader}>
      <CircularProgress />
    </div>
  ) : null;
};
